// Dependencies
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Container, Header, List, ListItem, Paper, Table } from "dibk-design";

// Partials
import MainHeading from "components/partials/MainHeading";

// Helpers
import { classNameArrayToClassNameString, scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";

const ErklaeringOmInformasjonskapsler = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container>
            <Paper>
                <MainHeading
                    pageHeading="Erklæring om informasjonskapsler"
                    pageTitle="Erklæring om informasjonskapsler - Tiltakshavers samtykke til byggeplaner"
                />
                <span className={classNameArrayToClassNameString([commonStyle.subtitle, commonStyle.marginBottom])}>
                    <i>Sist oppdatert 20.04.2022</i>
                </span>
                <Header content="Bruk av cookies i Tiltakshavers samtykke til byggeplaner" size={2} />
                <p className={commonStyle.noMarginTop}>
                    Tiltakshavers samtykke til byggeplaner fra Direktoratet for byggkvalitet (DiBK) bruker «session
                    storage» for å kunne fungere. «Session storage» er lagring lokalt på din enhet, og behandler ikke
                    personopplysninger. Lagret informasjon slettes når sesjonen er slutt og nettleseren lukkes.
                </p>
                <p>
                    Vi bruker ikke egne informasjonskapsler (cookies). Informasjonskapsler er små tekstfiler som lagres
                    på enheten du bruker når du besøker et nettsted.
                </p>
                <Table>
                    <caption>Nettstedsdata</caption>
                    <thead>
                        <tr>
                            <th>Leverandør</th>
                            <th>Navn</th>
                            <th>Type</th>
                            <th>Formål</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DiBK</td>
                            <td>oidc</td>
                            <td>Session storage</td>
                            <td>Autentiserer innloggingen din</td>
                        </tr>
                        <tr>
                            <td>DiBK</td>
                            <td>signinRedirectPath</td>
                            <td>Session storage</td>
                            <td>Sørger for kontekst i tjenesten</td>
                        </tr>
                    </tbody>
                </Table>

                <p className={commonStyle.marginTopSmall}>
                    Tiltakshavers samtykke til byggeplaner ligger på DiBK sitt domene. Se{" "}
                    <a href="https://dibk.no/om-oss/personvernerklaring/" target="_blank" rel="noopener noreferrer">
                        DiBK sin personvernerklæring med cookies-informasjon
                    </a>{" "}
                    for mer informasjon om hvordan direktoratet håndterer informasjonskapsler på sine nettsider.
                </p>

                <p>
                    Tiltakshavers samtykke til byggeplaner bruker Altinn til distribusjon og lagring, og ID-porten til å
                    sikre identiteten din når du logger deg inn i tjenesten. Løsningene leveres av
                    Digitaliseringsdirektoratet. Både Altinn og ID-porten bruker informasjonskapsler i løsningene sine,
                    og har egne erklæringer om informasjonskapsler som du kan lese ved å trykke på lenkene under:
                </p>

                <List>
                    <ListItem>
                        <a
                            href="https://eid.difi.no/nb/sikkerhet-og-informasjonskapsler/personvern-og-informasjonskapsler"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ID-porten sin erklæring om personvern og informasjonskapsler
                        </a>
                    </ListItem>
                    <ListItem>
                        <a
                            href="https://www.altinn.no/om-altinn/personvern/#informasjonskapsler-p%C3%A5-altinn-no"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Informasjonskapsler på altinn.no
                        </a>
                    </ListItem>
                </List>

                <p>
                    Bruk av informasjonskapsler på nettsider reguleres av ekomloven § 2-7 b. I den grad en
                    informasjonskapsel også behandler personopplysninger om deg, gjelder også personopplysningsloven.
                    Les mer om hvordan vi innhenter personopplysninger i{" "}
                    <Link to="/personvernerklaering" title="Personvernerklæring">
                        personvernerklæringen vår
                    </Link>
                    .
                </p>

                <p>
                    Hvis du har spørsmål om hvordan vi bruker informasjonskapsler, kan du ta kontakt med oss på{" "}
                    <a href="mailto:ftb@dibk.no">ftb@dibk.no</a>.
                </p>
                <p>
                    Du kan lese mer om informasjonskapsler på{" "}
                    <a
                        href="https://www.nkom.no/internett/informasjonskapsler-cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Nasjonal kommunikasjonsmyndighet sine nettsider
                    </a>
                    .
                </p>
            </Paper>
        </Container>
    );
};

export default ErklaeringOmInformasjonskapsler;
