// Dependencies
import { useEffect } from "react";

// DIBK Design
import { Container, Header, Paper } from "dibk-design";

// Helpers
import { scrollToTop } from "helpers/guiHelpers";
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";

const About = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    const altinnInboxReference = `${getEnvironmentVariable("altinnServerUrl")}/ui/messagebox/Archive`;

    return (
        <Container>
            <div className={commonStyle.headerSection}>
                <Header content="Tiltakshavers samtykke til byggeplaner"></Header>
                <Paper>
                    <p>
                        Før du kan bruke tjenesten, må du få en unik lenke fra ansvarlig søker. Gå inn på lenken fra
                        ansvarlig søker for å logge inn og godkjenne byggesøknaden.
                    </p>
                    <p>
                        Hvis du allerede har sendt ditt samtykke, kan du finne kopi av samtykket og søknaden med vedlegg
                        i ditt arkiv i Altinn.
                    </p>
                    <p>
                        <a href={altinnInboxReference}>Trykk her for å se arkivet ditt i Altinn.</a>
                    </p>
                </Paper>
            </div>
        </Container>
    );
};

export default About;
