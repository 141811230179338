export const getProsjektnavn = (samtykkeTiltakshaver) => {
    if (samtykkeTiltakshaver?.Prosjektnavn) {
        return samtykkeTiltakshaver.Prosjektnavn;
    } else if (samtykkeTiltakshaver?.Adresselinje1) {
        return samtykkeTiltakshaver.Adresselinje1;
    } else {
        return 'dette prosjektet'
    }
}

export const getAnsvarligSoeker = (samtykkeTiltakshaver) => {
    return samtykkeTiltakshaver?.AnsvarligSoeker?.trim().length ? samtykkeTiltakshaver?.AnsvarligSoeker : 'ansvarlig søker';
}

export const getSoknadList = (samtykkeTiltakshaver) => {
    return samtykkeTiltakshaver?.Vedlegg?.length
        ? samtykkeTiltakshaver.Vedlegg.filter((vedlegg) => {
            return vedlegg.Vedleggstype === 'Soknad';
        })
        : null
}

export const getVedleggList = (samtykkeTiltakshaver) => {
    return samtykkeTiltakshaver?.Vedlegg?.length
        ? samtykkeTiltakshaver.Vedlegg.filter((vedlegg) => {
            return vedlegg.Vedleggstype !== 'Soknad';
        })
        : null
}

