// Stylesheets
import style from "components/partials/ReporteeInfo.module.scss";
import { useSelector } from "react-redux";

const ReporteeInfo = () => {
    // Redux store
    const selectedReportee = useSelector((state) => state.selectedReportee);

    return selectedReportee && Object.keys(selectedReportee).length ? (
        <div className={style.reporteeInfo}>
            <span className={style.label}>Du representer:</span>
            <span className={style.name}>{selectedReportee.Name}</span>
        </div>
    ) : (
        ""
    );
};

export default ReporteeInfo;
