// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

export const getAttachmentDownloadUrl = (attachment, guid) => {
    return `${getEnvironmentVariable('internalServerUrl')}/api/internal/samtykketiltakshaver/${guid}/vedlegg/${attachment.VedleggId}`;
}

export const getAttachmentUploadUrl = (attachment, messageId) => {
    return `${messageId}/attachments/streamedattachment?filename=${attachment.Navn}&attachmenttype=${attachment.Vedleggstype}`;
}

export const getAltinnInboxUrl = () => {
    return `${getEnvironmentVariable('altinnServerUrl')}/ui/messagebox/Archive`;
}