// Types
import { UPDATE_MESSAGE_ID } from "constants/types";

// Actions
import { updateInternalSamtykkeTiltakshaver } from "actions/SamtykkeTiltakshaverActions";
// Actions
import { updateAttachmentsTransferComplete } from "actions/AttachmentsTransferCompleteActions";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers";

export const postMessage = (tiltakshaverSignaturXml, selectedReportee) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;

    const who = selectedReportee.ReporteeId;
    const apiUrl =
        selectedReportee?._links?.messages?.href ||
        `${getEnvironmentVariable("altinnServerUrl")}/api/${who}/messages?complete=false`;
    const postData = {
        Type: "FormTask",
        ServiceCode: 5303,
        ServiceEdition: 1,
        _embedded: {
            forms: [
                {
                    Type: "MainForm",
                    FormData: tiltakshaverSignaturXml,
                    DataFormatId: 6147,
                    DataFormatVersion: 44097
                }
            ]
        }
    };

    const apiKey = getEnvironmentVariable("altinnApiKey");
    const bearerToken = `Bearer ${accessToken}`;

    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: bearerToken,
            ApiKey: apiKey,
            "Content-Type": "application/hal+json"
        },
        xhrFields: {
            withCredentials: true
        },
        credentials: "include",
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, fetchOptions)
        .then((response) => {
            const messageId = response.headers.get("location");
            const logMessage = {
                level: "Debug",
                path: apiUrl,
                message: `Created Altinn message at '${messageId}'`
            };
            clientLogger.postLogData([logMessage]);
            dispatch({
                type: UPDATE_MESSAGE_ID,
                payload: messageId
            });
            return messageId;
        })
        .catch((error) => {
            const logMessage = {
                level: "Error",
                path: apiUrl,
                message: "Failed to create Altinn message"
            };
            clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                clientLogger.postLogData([logMessage]);
            });
        });
};

export const updateMessage = (messageId) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;
    const apiUrl = `${messageId}?complete=true&sign=true`;
    const attachmentsStatus = getState()?.attachmentsStatus;
    const attachmentsTransferComplete = getState()?.attachmentsTransferComplete;

    const allAttachmentsIsTransfered = attachmentsStatus?.every((attachment) => {
        return attachment.downloadProgress === 100 && attachment.uploadProgress === 100;
    });

    if (apiUrl && allAttachmentsIsTransfered && !attachmentsTransferComplete) {
        dispatch(updateAttachmentsTransferComplete(true));

        const postData = {
            Type: "FormTask",
            ServiceCode: 5303,
            ServiceEdition: 1
        };

        const apiKey = getEnvironmentVariable("altinnApiKey");
        const bearerToken = `Bearer ${accessToken}`;

        const fetchOptions = {
            method: "PUT",
            headers: {
                Authorization: bearerToken,
                ApiKey: apiKey,
                "Content-Type": "application/hal+json"
            },
            xhrFields: {
                withCredentials: true
            },
            credentials: "include",
            body: JSON.stringify(postData)
        };

        return fetch(apiUrl, fetchOptions)
            .then((response) => {
                const logMessage = {
                    level: "Debug",
                    path: apiUrl,
                    message: `Updated Altinn message at '${messageId}'`
                };
                clientLogger.postLogData([logMessage]);
                const messageLocationUrl = response.headers.get("location");
                dispatch(updateInternalSamtykkeTiltakshaver(messageLocationUrl));
            })
            .catch((error) => {
                const logMessage = {
                    level: "Error",
                    path: apiUrl,
                    message: "Failed to update Altinn message"
                };
                clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                    clientLogger.postLogData([logMessage]);
                });
            });
    }
};
