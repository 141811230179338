// Dependencies
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

// DIBK Design
import { Container, Header, LoadingAnimation, Paper, ToggleNavigationButton, WizardNavigation } from "dibk-design";

// Wizard steps
import SamtykkeTilByggeplaner from "components/routes/Wizard/SamtykkeTilByggeplaner";
import VelgTiltakshaver from "components/routes/Wizard/VelgTiltakshaver";
import GiDittSamtykke from "components/routes/Wizard/GiDittSamtykke";
import Kvittering from "components/routes/Wizard/Kvittering";

// Partials
import AttachmentsStatusModal from "components/partials/AttachmentsStatusModal";

// Actions
import { fetchSamtykkeTiltakshaver } from "actions/SamtykkeTiltakshaverActions";
import { updateLoadingMessage } from "actions/LoadingMessageActions";

// Stylesheets
import style from "components/routes/Wizard.module.scss";
import ReporteeInfo from "components/partials/ReporteeInfo";
import { classNameArrayToClassNameString } from "helpers/guiHelpers";

const Wizard = ({ userManager }) => {
    const params = useParams();
    const dispatch = useDispatch();

    const defaultStepId = "samtykke-til-byggeplaner";

    // Url parameters
    const guid = params?.guid || null;
    const activeStepId = params?.stepId || defaultStepId;

    // Redux store
    const samtykkeTiltakshaver = useSelector((state) => state.samtykkeTiltakshaver);
    const wizardSteps = useSelector((state) => state.wizardSteps);
    const loadingMessage = useSelector((state) => state.loadingMessage);
    const accessToken = useSelector((state) => state.oidc?.user?.access_token);

    // State
    const [samtykkeTiltakshaverIsLoaded, setSamtykkeTiltakshaverIsLoaded] = useState();
    const [showWizardNavigationSteps, setShowWizardNavigationSteps] = useState(false);

    useEffect(() => {
        userManager?.events.addAccessTokenExpiring(() => {});
        userManager?.events.addAccessTokenExpired(() => {
            return userManager.signinSilent();
        });
        userManager.events.addSilentRenewError((error) => {
            console.log("silent renew errored", error);
        });
    }, [userManager]);

    useEffect(() => {
        if (!samtykkeTiltakshaver) {
            setSamtykkeTiltakshaverIsLoaded(false);
            dispatch(updateLoadingMessage("Henter informasjon om byggeplaner"));
            dispatch(fetchSamtykkeTiltakshaver(guid)).finally(() => {
                setSamtykkeTiltakshaverIsLoaded(true);
                dispatch(updateLoadingMessage(null));
            });
        } else {
            setSamtykkeTiltakshaverIsLoaded(true);
        }
    }, [samtykkeTiltakshaver, guid, dispatch]);

    const renderStep = () => {
        switch (activeStepId) {
            case "samtykke-til-byggeplaner":
                return <SamtykkeTilByggeplaner userManager={userManager} />;
            case "velg-tiltakshaver":
                return <VelgTiltakshaver />;
            case "gi-ditt-samtykke":
                return <GiDittSamtykke />;
            case "kvittering":
                return <Kvittering userManager={userManager} />;
            default:
                return "";
        }
    };

    const showWizardNavigation = !!accessToken && samtykkeTiltakshaver?.Status === "Opprettet";

    function getStepNumber(stepId, wizardSteps) {
        return wizardSteps && Object.keys(wizardSteps)?.findIndex((step) => step === stepId) + 1;
    }

    function getWizardNumberOfSteps() {
        return wizardSteps && Object.keys(wizardSteps)?.length;
    }

    return (
        <Fragment>
            {samtykkeTiltakshaverIsLoaded ? (
                <Fragment>
                    <Helmet>
                        <meta name="robots" content="noindex"></meta>
                    </Helmet>

                    {showWizardNavigation ? (
                        <Container maxWidth="1320px">
                            <div className={style.mainContainer}>
                                <div className={style.toggleNavigationButtonContainer}>
                                    <ToggleNavigationButton
                                        isOpen={showWizardNavigationSteps}
                                        buttonProps={{
                                            onClick: () => setShowWizardNavigationSteps(!showWizardNavigationSteps)
                                        }}
                                        showText={`${getStepNumber(
                                            activeStepId,
                                            wizardSteps
                                        )} av ${getWizardNumberOfSteps()} steg`}
                                        hideText="Skjul alle steg"
                                    />
                                </div>
                                <div
                                    className={classNameArrayToClassNameString([
                                        style.navigationContainer,
                                        showWizardNavigationSteps && style.expanded
                                    ])}
                                >
                                    <div className={style.headerSection}>
                                        <Header
                                            label="Veiviser"
                                            content="Samtykke til byggeplaner"
                                            size={3}
                                            htmlTag="p"
                                        ></Header>
                                    </div>
                                    <ReporteeInfo />
                                    <WizardNavigation steps={wizardSteps} activeStepId={activeStepId} />
                                </div>
                                <div className={style.contentContainer}>
                                    <Paper>{renderStep()}</Paper>
                                </div>
                            </div>
                        </Container>
                    ) : (
                        <Container maxWidth="836px">
                            <Paper>{renderStep()}</Paper>
                        </Container>
                    )}
                </Fragment>
            ) : null}
            {loadingMessage?.length ? <LoadingAnimation fixed message={loadingMessage} /> : ""}
            <AttachmentsStatusModal />
        </Fragment>
    );
};

export default Wizard;
