// Dependencies
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// DIBK Design
import { Button, Header, InputField, Paper } from "dibk-design";

// Partials
import MainHeading from "components/partials/MainHeading";

// Actions
import { validateWizardSteps } from "actions/WizardStepsActions";
import { sendReceiptEmail } from "actions/EmailActions";

// Helpers
import { getAltinnInboxUrl } from "helpers/urlHelpers";
import { scrollToTop } from "helpers/guiHelpers";
import { validateEmailField } from "helpers/emailHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";
import style from "components/routes/Wizard/Kvittering.module.scss";

const Kvittering = ({ userManager }) => {
    const dispatch = useDispatch();

    // Redux store
    const samtykkeTiltakshaver = useSelector((state) => state.samtykkeTiltakshaver);
    const attachmentsTransferComplete = useSelector((state) => state.attachmentsTransferComplete);
    const archiveReference = useSelector((state) => state.archiveReference);
    const emailReceiptIsSent = useSelector((state) => state.emailReceiptIsSent);
    const user = useSelector((state) => state.oidc?.user);
    // const emailReceiptIsSent = useSelector(state => state.emailReceiptIsSent);

    // State
    const [emailAddress, setEmailAddress] = useState();
    const [emailAddressIsValid, setEmailAddressIsValid] = useState();
    const [emailAddressIsValidated, setEmailAddressIsValidated] = useState();

    const altinnInboxUrl = getAltinnInboxUrl();

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        dispatch(validateWizardSteps("kvittering"));
    }, [attachmentsTransferComplete, archiveReference, dispatch]);

    const handleEmailAddressOnBlur = () => {
        setEmailAddressIsValidated(true);
    };

    const handleEmailAddressOnChange = (event) => {
        const newEmailAddress = event?.target?.value;
        setEmailAddress(newEmailAddress);
        setEmailAddressIsValid(validateEmailField(newEmailAddress));
    };

    const handleSendReceiptEmailButtonClick = () => {
        dispatch(sendReceiptEmail(samtykkeTiltakshaver, archiveReference, emailAddress));
    };

    const handleLogoutClick = (event) => {
        event.preventDefault();
        userManager.signoutRedirect({
            id_token_hint: user?.id_token
        });
        userManager.removeUser();
    };

    return (
        <Fragment>
            <MainHeading
                pageHeading="Du har nå gitt ditt samtykke"
                pageTitle="Du har nå gitt ditt samtykke - Tiltakshavers samtykke til byggeplaner"
            />
            <p>
                Samtykket er sendt til ansvarlig søker. Kopi av samtykket og søknaden med vedlegg blir lagret i ditt
                arkiv i Altinn, med referanse <strong>{archiveReference}</strong>.
            </p>
            <a href={altinnInboxUrl}>Trykk her for å se arkivet ditt i Altinn</a>
            <Paper>
                <div className={commonStyle.textCenter}>
                    <Header content="Ønsker du kvittering på e-post?" size={2} />
                    {emailReceiptIsSent ? (
                        <div>
                            <p>Kvittering er sendt på e-post til {emailAddress}</p>
                        </div>
                    ) : (
                        <div className={style.inputGroup}>
                            <div className={style.inputField}>
                                <InputField
                                    type="email"
                                    id="email-address"
                                    elementKey="email-address"
                                    label="Din e-postadresse"
                                    required
                                    onChange={handleEmailAddressOnChange}
                                    onBlur={handleEmailAddressOnBlur}
                                    hasErrors={emailAddressIsValidated && !emailAddressIsValid}
                                    errorMessage={
                                        emailAddressIsValidated && !emailAddressIsValid
                                            ? "Har du skrevet riktig e-postadresse? Gyldig e-post skrives som navn@domene.no"
                                            : ""
                                    }
                                    name="email"
                                    aria-autocomplete="inline"
                                />
                            </div>
                            <div className={style.button}>
                                <Button
                                    disabled={!emailAddressIsValid}
                                    onClick={handleSendReceiptEmailButtonClick}
                                    content="Send meg kvittering"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Paper>
            <div className={commonStyle.buttonRow}>
                <Button color="primary" content="Logg ut" onClick={handleLogoutClick} />
            </div>
        </Fragment>
    );
};

export default Kvittering;
