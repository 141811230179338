// Types
import { FETCH_SAMTYKKE_TILTAKSHAVER, UPDATE_ARCHIVE_REFERENCE } from "constants/types";

// Actions
import { updateShowAttachmentStatusModal } from "./ShowAttachmentsStatusActions";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

export const fetchSamtykkeTiltakshaver = (guid) => (dispatch, getState) => {
    const clientLogger = getState()?.clientLogger;

    const internalApiUrl = `${getEnvironmentVariable("internalServerUrl")}/api`;
    const apiUrl = `${internalApiUrl}/internal/samtykketiltakshaver/${guid}`;
    return fetch(apiUrl)
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error(res?.statusText);
            }
        })
        .then((payload) => {
            const logMessage = {
                level: "Debug",
                path: apiUrl,
                message: "Fetched SamtykkeTiltakshaver"
            };
            clientLogger.postLogData([logMessage]);
            return dispatch({
                type: FETCH_SAMTYKKE_TILTAKSHAVER,
                payload
            });
        })
        .catch((error) => {
            const logMessage = {
                level: "Error",
                path: apiUrl,
                message: "Failed to fetch SamtykkeTiltakshaver"
            };
            clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                clientLogger.postLogData([logMessage]);
            });
        });
};

export const updateInternalSamtykkeTiltakshaver = (messageLocationUrl) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;

    // Get Altinn archive reference from message location url
    const altinnArkivreferanseRegex = /\/api\/[a-z0-9]*\/messages\/([a-z0-9]*)/i;
    const altinnArkivreferanse = altinnArkivreferanseRegex.exec(messageLocationUrl)[1]?.replace("b", "AR");

    const samtykkeTiltakshaver = getState()?.samtykkeTiltakshaver;
    const selectedReportee = getState()?.selectedReportee;

    let postData = {
        Id: samtykkeTiltakshaver?.Id,
        Tiltakshaver: samtykkeTiltakshaver?.Tiltakshaver,
        AltinnArkivreferanse: altinnArkivreferanse
    };
    if (selectedReportee?.OrganizationNumber) {
        postData.TiltakshaverOrganisasjonsnummer = selectedReportee.OrganizationNumber;
    }

    const internalApiUrl = `${getEnvironmentVariable("internalServerUrl")}/api`;
    const apiUrl = `${internalApiUrl}/internal/samtykketiltakshaver/${samtykkeTiltakshaver?.Id}/signert`;
    const apiKey = getEnvironmentVariable("altinnApiKey");
    const bearerToken = `Bearer ${accessToken}`;

    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: bearerToken,
            ApiKey: apiKey,
            "Content-Type": "application/json"
        },
        xhrFields: {
            withCredentials: false
        },
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, fetchOptions)
        .then((response) => {
            if (response.ok) {
                const logMessage = {
                    level: "Debug",
                    path: apiUrl,
                    message: `Updated SamtykkeTiltakshaver ${
                        altinnArkivreferanse?.length
                            ? "with archive reference '" + altinnArkivreferanse + "'"
                            : "without archive reference"
                    }`
                };
                clientLogger.postLogData([logMessage]);
                dispatch({
                    type: UPDATE_ARCHIVE_REFERENCE,
                    payload: altinnArkivreferanse
                });
                dispatch(updateShowAttachmentStatusModal(false));
                dispatch(fetchSamtykkeTiltakshaver(samtykkeTiltakshaver?.Id));
            } else {
                const messageDescription = "Failed to update SamtykkeTiltakshaver";
                const logMessage = {
                    level: "Warning",
                    message: response?.reponseText?.length
                        ? `${response.reponseText} | ${messageDescription}`
                        : messageDescription,
                    statuscode: response?.status,
                    path: response?.url
                };
                clientLogger.postLogData([logMessage]);
            }
        })
        .catch((error) => {
            const logMessage = {
                level: "Error",
                path: apiUrl,
                message: "Failed to update SamtykkeTiltakshaver"
            };
            clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                clientLogger.postLogData([logMessage]);
            });
        });
};
