import { FETCH_AVAILABLE_REPORTEES, UPDATE_AVAILABLE_REPORTEES } from 'constants/types';

const initialState = null;

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_AVAILABLE_REPORTEES:
			return action.payload;
		case UPDATE_AVAILABLE_REPORTEES:
			return action.payload;
		default:
			return state;
	}
}

export default reducer;
