// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// DIBK Design
import { RadioButtonListItem } from 'dibk-design';

// Assets
import foretakImage from 'assets/images/foretak.png';
import personImage from 'assets/images/person.png';


// Actions
import { updateSelectedReportee } from 'actions/ReporteesActions';
import { renewLastInteractionAt } from 'actions/LastInteractionAtActions';

// Stylesheets
import style from 'components/partials/ReporteesList/ReporteesListItem.module.scss';

const ReporteesListItem = ({ reportee, hasErrors }) => {

  const dispatch = useDispatch();

  // Redux store
  const selectedReportee = useSelector(state => state.selectedReportee);

  const getReporteeImageUrl = (reportee) => {
    switch (reportee.Type) {
      case 'Enterprise':
      case 'Foretak':
      case 'Føretak':
        return foretakImage;
      case 'Business':
      case 'Bedrift':
        return foretakImage;
      case 'Person':
        return personImage;
      default:
        return '';
    }
  }


  const handleOnChange = () => {
    dispatch(updateSelectedReportee(reportee));
    dispatch(renewLastInteractionAt());
  }

  return (
    <li>
      <RadioButtonListItem
        id={reportee.ReporteeId}
        name="selectedReportee"
        checked={selectedReportee?.ReporteeId === reportee.ReporteeId}
        inputValue={reportee.ReporteeId}
        hasErrors={hasErrors}
        aria-describedby={hasErrors ? "selectedReportee-errorMessage" : "step-heading"}
        required
        onChange={handleOnChange}
      >
        <div className={style.radioButtonLabelContainer}>
          <div className={style.radioButtonLabelText}>
            <span>{reportee.Name}</span>
            {reportee.OrganizationNumber
              ? (
                <span className={style.subTitle}>Orgnr. {reportee.OrganizationNumber}</span>
              )
              : ''
            }
          </div>
          <img className={style.radioButtonLabelImage} alt="" src={getReporteeImageUrl(reportee)} />
        </div>
      </RadioButtonListItem>
    </li>
  )
}

export default ReporteesListItem;
