// Dependencies
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// DIBK Design
import { Header } from "dibk-design";

// Stylesheets
import commonStyle from "components/common.module.scss";

const renderPageHeading = (pageHeading, id, label) => {
    const headerProps = {
        id: id || null,
        content: pageHeading,
        label: label || null
    }
    return (
        <div className={commonStyle.headerSection}>
            <Header {...headerProps}></Header>
        </div>
    );
};

const MainHeading = ({ pageHeading, pageTitle, id, label }) => {
    return (
        <Fragment>
            <Helmet>
                <title>{pageTitle} - DiBK</title>
                <meta property="og:title" content={`${pageTitle} - DiBK`} />
                <meta property="twitter:title" content={`${pageTitle} - DiBK`} />
            </Helmet>
            {renderPageHeading(pageHeading, id, label)}
        </Fragment>
    );
};

MainHeading.propTypes = {
    pageHeading: PropTypes.string,
    pageTitle: PropTypes.string,
    id: PropTypes.string
};

export default MainHeading;
