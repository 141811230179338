// Types
import {
    UPDATE_SINGLE_ATTACHMENT_STATUS_ATTACHMENT,
    UPDATE_SINGLE_ATTACHMENT_STATUS_DOWNLOAD_PROGRESS,
    UPDATE_SINGLE_ATTACHMENT_STATUS_UPLOAD_PROGRESS,
    UPDATE_SINGLE_ATTACHMENT_STATUS_ERROR_MESSAGES
} from 'constants/types';

export const updateSingleAttachmentStatusAttachment = (attachment, attachmentIndex) => (dispatch) => {
    dispatch({ type: UPDATE_SINGLE_ATTACHMENT_STATUS_ATTACHMENT, payload: attachment, index: attachmentIndex });
}

export const updateSingleAttachmentStatusDownloadProgress = (downloadProgress, attachmentIndex) => (dispatch) => {
    dispatch({ type: UPDATE_SINGLE_ATTACHMENT_STATUS_DOWNLOAD_PROGRESS, payload: downloadProgress, index: attachmentIndex });
}

export const updateSingleAttachmentStatusUploadProgress = (uploadProgress, attachmentIndex) => (dispatch) => {
    dispatch({ type: UPDATE_SINGLE_ATTACHMENT_STATUS_UPLOAD_PROGRESS, payload: uploadProgress, index: attachmentIndex });
}

export const addSingleAttachmentStatusErrorMessage = (errorMessage, attachmentIndex) => (dispatch) => {
    dispatch({ type: UPDATE_SINGLE_ATTACHMENT_STATUS_ERROR_MESSAGES, payload: errorMessage, index: attachmentIndex });
}

