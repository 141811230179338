// Dependencies
import React, { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// DIBK Design
import { Button, InfoBox } from "dibk-design";

// Actions
import { validateWizardSteps } from "actions/WizardStepsActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Helpers
import { getProsjektnavn, getAnsvarligSoeker } from "helpers/samtykkeTiltakshaverHelpers";
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";
import MainHeading from "components/partials/MainHeading";
import { Header } from "dibk-design";

const SamtykkeTilByggeplaner = ({ userManager }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Url parameters
    const guid = params?.guid || null;

    // Redux store
    const accessToken = useSelector((state) => state.oidc?.user?.access_token);
    const samtykkeTiltakshaver = useSelector((state) => state.samtykkeTiltakshaver);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        dispatch(validateWizardSteps("samtykke-til-byggeplaner"));
    }, [dispatch]);

    const handleLoginClick = (event) => {
        event.preventDefault();
        sessionStorage.signinRedirectPath = `/veiviser/${guid}/velg-tiltakshaver`;
        userManager.signinRedirect();
    };

    const handleNextButtonClick = () => {
        dispatch(renewLastInteractionAt());
        navigate(`/veiviser/${guid}/velg-tiltakshaver`);
    };

    const renderActionButtons = (status) => {
        if (status === "Opprettet") {
            return !accessToken ? (
                <div className={commonStyle.marginTop}>
                    <Button content="Logg inn" color="primary" onClick={handleLoginClick} />
                </div>
            ) : (
                <div className={commonStyle.marginTop}>
                    <Button color="primary" onClick={handleNextButtonClick} content="Neste" arrow="right" />
                </div>
            );
        }
    };

    const renderStatusContent = (status) => {
        if (status !== "Opprettet") {
            const altinnInboxReference = `${getEnvironmentVariable("altinnServerUrl")}/ui/messagebox/Archive`;
            return (
                <InfoBox>
                    <p>
                        Du har allerede sendt ditt samtykke til byggesøknaden for{" "}
                        {getProsjektnavn(samtykkeTiltakshaver)}.
                    </p>
                    <p>
                        Samtykket er sendt til {getAnsvarligSoeker(samtykkeTiltakshaver)}. Kopi av samtykket og søknaden
                        med vedlegg finner du i ditt arkiv i Altinn.
                    </p>
                    <p>
                        <a href={altinnInboxReference}>Trykk her for å se arkivet ditt i Altinn.</a>
                    </p>
                </InfoBox>
            );
        }
    };

    const renderDeletedStatusInfo = () => {
        return (
            <Fragment>
                <MainHeading
                    pageHeading="Tiltakshavers samtykke til byggeplaner - Utgått"
                    pageTitle="Tiltakshavers samtykke til byggeplaner - Utgått"
                />
                <section>
                    <Header size={2} content="Lenken er utgått" />
                    <div className={commonStyle.introText}>
                        <div className={commonStyle.paragraphGroup}>
                            <p>
                                Vi har slettet informasjonen om byggesøknaden fordi det har gått mer enn 12 måneder
                                siden den ble sendt fra ansvarlig søker.{" "}
                                <a
                                    href={`${window?.location?.origin}/personvernerklaering`}
                                    target="_blank"
                                    title="Personvernerklæring"
                                    rel="noopener noreferrer"
                                >
                                    Les mer om hvordan vi behandler data i personvernerklæringen.
                                </a>
                            </p>
                        </div>
                        <div className={commonStyle.paragraphGroup}>
                            <p>
                                Hvis du har samtykket til byggeplanene tidligere, finner du en kopi av samtykket og
                                søknaden i arkivet ditt i Altinn.
                            </p>
                        </div>
                        <div className={commonStyle.paragraphGroup}>
                            <p>Ta kontakt med ansvarlig søker dersom du ønsker å få tilsendt byggesøknaden på nytt.</p>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    };

    return (
        <Fragment>
            {samtykkeTiltakshaver ? (
                samtykkeTiltakshaver?.Status === "Slettet" ? (
                    renderDeletedStatusInfo()
                ) : (
                    <Fragment>
                        <MainHeading
                            pageHeading="Tiltakshavers samtykke til byggeplaner"
                            pageTitle="Logg inn - Tiltakshavers samtykke til byggeplaner"
                            label="Veiviser"
                        />
                        <section>
                            <div className={commonStyle.marginBottom}>
                                <p>Du må godkjenne byggesøknaden før den kan sendes til kommunen.</p>
                                <p>
                                    Som tiltakshaver har du hovedansvaret for det som skal bygges. Du får hjelp av
                                    ansvarlig søker, som påtar seg ansvaret for byggesøknaden og all kommunikasjon med
                                    kommunens byggesaksavdeling.
                                </p>
                                <p>Forsikre deg om at du har lest alle dokumentene før du samtykker.</p>
                            </div>
                            {renderStatusContent(samtykkeTiltakshaver?.Status)}
                        </section>
                        {renderActionButtons(samtykkeTiltakshaver?.Status)}
                    </Fragment>
                )
            ) : (
                <React.Fragment>
                    <MainHeading
                        pageHeading="Kan ikke starte veiviseren"
                        pageTitle="Kan ikke starte veiviseren - Tiltakshavers samtykke til byggeplaner"
                    />
                    <p>
                        Noe har dessverre gått galt ved nedlasting av veiviseren for tiltakshavers signatur til
                        byggeplaner.
                    </p>
                    <p>Ta kontakt med ansvarlig søker, og be dem om å sende e-post med en ny lenke.</p>
                </React.Fragment>
            )}
        </Fragment>
    );
};

export default SamtykkeTilByggeplaner;
