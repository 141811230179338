import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from "redux-oidc";

const OidcCallback = ({ userManager }) => {
    const navigate = useNavigate();

    const successCallback = () => {
        const pathname = sessionStorage?.signinRedirectPath || '';
        sessionStorage.removeItem('signinRedirectPath');
        navigate(pathname);
    };

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={successCallback}
            errorCallback={() => {
                navigate("/");
            }}
        >
            <div>Logger inn...</div>
        </CallbackComponent>
    );
};

export default OidcCallback;
