// Dependencies
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

// DIBK Design
import {
    Button,
    CheckBoxInput,
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
    ErrorBox,
    Header,
    List,
    ListItem
} from "dibk-design";

// Partials
import MainHeading from "components/partials/MainHeading";

// Actions
import { updateHasSamtykke } from "actions/HasSamtykkeActions";
import { postMessage } from "actions/MessageActions";
import { fetchTiltakshaverSignaturXml } from "actions/TiltakshaverSignaturXmlActions";
import { streamAttachments, getAttachementFile } from "actions/AttachmentActions";
import { updateShowAttachmentStatusModal } from "actions/ShowAttachmentsStatusActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";
import { fetchSamtykkeTiltakshaver } from "actions/SamtykkeTiltakshaverActions";
import { validateWizardSteps } from "actions/WizardStepsActions";

// Helpers
import { getSoknadList, getVedleggList } from "helpers/samtykkeTiltakshaverHelpers";
import { formatFilesize, formatAddress } from "helpers/formatHelpers";
import { scrollToTop } from "helpers/guiHelpers";
import { saveFileContentFromBlob } from "helpers/fileHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";

const GiDittSamtykke = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Url parameters
    const guid = params?.guid || null;

    // Redux store
    const samtykkeTiltakshaver = useSelector((state) => state.samtykkeTiltakshaver);
    const selectedReportee = useSelector((state) => state.selectedReportee);
    const hasSamtykke = useSelector((state) => state.hasSamtykke);
    const attachmentsTransferComplete = useSelector((state) => state.attachmentsTransferComplete);
    const archiveReference = useSelector((state) => state.archiveReference);

    // State
    const [hasSamtykkeIsValidated, setHasSamtykkeIsValidated] = useState();
    const [sendingSamtykke, setSendingSamtykke] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (attachmentsTransferComplete && archiveReference) {
            navigate(`/veiviser/${guid}/kvittering`);
        }
    }, [attachmentsTransferComplete, guid, archiveReference, navigate]);

    useEffect(() => {
        if (!selectedReportee) {
            navigate(`/veiviser/${guid}/velg-tiltakshaver`);
        }
    }, [selectedReportee, guid, navigate]);

    useEffect(() => {
        if (samtykkeTiltakshaver && samtykkeTiltakshaver.Status !== "Opprettet") {
            navigate(`/veiviser/${guid}/samtykke-til-byggeplaner`);
        } else {
            dispatch(validateWizardSteps("gi-ditt-samtykke"));
        }
    }, [samtykkeTiltakshaver, guid, selectedReportee, navigate, dispatch]);

    const toggleHasSamtykke = () => {
        dispatch(renewLastInteractionAt());
        dispatch(updateHasSamtykke(!hasSamtykke));
    };

    const handleDownloadFileClick = (file, guid) => {
        dispatch(renewLastInteractionAt());
        dispatch(getAttachementFile(file, guid)).then((response) => {
            if (response.status) {
                console.warn("Kunne ikke laste ned fil.", response);
            } else {
                saveFileContentFromBlob(response, file.Navn);
            }
        });
    };

    const handleSendSamtykkeClick = () => {
        dispatch(renewLastInteractionAt());
        setHasSamtykkeIsValidated(true);
        if (hasSamtykke) {
            setSendingSamtykke(true);
            dispatch(updateShowAttachmentStatusModal(true));
            dispatch(fetchSamtykkeTiltakshaver(guid)).then((action) => {
                const fetchedSamtykkeTiltakshaver = action.payload;
                const hasOpprettetStatus = fetchedSamtykkeTiltakshaver?.Status === "Opprettet";
                if (hasOpprettetStatus) {
                    dispatch(fetchTiltakshaverSignaturXml(guid)).then((tiltakshaverSignaturXml) => {
                        dispatch(postMessage(tiltakshaverSignaturXml, selectedReportee)).then((messageId) => {
                            dispatch(streamAttachments(samtykkeTiltakshaver, messageId));
                            setSendingSamtykke(false);
                        });
                    });
                } else {
                    navigate(`/veiviser/${guid}/samtykke-til-byggeplaner`);
                }
            });
        }
    };

    const handlePrevButtonClick = () => {
        dispatch(renewLastInteractionAt());
        navigate(`/veiviser/${guid}/velg-tiltakshaver`);
    };

    const renderFileList = (files) => {
        const fileListElements = files?.length
            ? files.map((file, index) => {
                  return (
                      <li key={`file-${index}`} className={commonStyle.fileListItem}>
                          <span className={commonStyle.fileListItemTitle}>{file.Vedleggstype}</span>
                          <button
                              className={commonStyle.fileListInfoPrimary}
                              onClick={() => handleDownloadFileClick(file, guid)}
                          >
                              {file.Navn}
                          </button>
                          <span className={commonStyle.fileListInfoSecondary}>{formatFilesize(file.Storrelse)}</span>
                      </li>
                  );
              })
            : null;
        return fileListElements ? <ul className={commonStyle.fileList}>{fileListElements}</ul> : "";
    };

    const renderSoknadList = () => {
        const soknadList = getSoknadList(samtykkeTiltakshaver);
        return soknadList?.length ? renderFileList(soknadList) : "";
    };

    const renderVedleggList = () => {
        const vedleggList = getVedleggList(samtykkeTiltakshaver);
        return vedleggList?.length ? renderFileList(vedleggList) : "";
    };

    const renderTiltakstyper = (samtykkeTiltakshaver) => {
        const tiltakTypeListElements = samtykkeTiltakshaver?.TiltakType?.length
            ? samtykkeTiltakshaver.TiltakType.filter((tiltakTypeItem) => {
                  return !!tiltakTypeItem?.kodebeskrivelse?.length;
              }).map((tiltakTypeItem) => {
                  return <li key={tiltakTypeItem.kodebeskrivelse}>{tiltakTypeItem.kodebeskrivelse}</li>;
              })
            : null;
        return !!tiltakTypeListElements?.length ? (
            <ul className={commonStyle.inlineList}>{tiltakTypeListElements}</ul>
        ) : null;
    };

    return selectedReportee ? (
        <Fragment>
            <MainHeading
                pageHeading="Gi ditt samtykke"
                pageTitle="Gi ditt samtykke - Tiltakshavers samtykke til byggeplaner"
            />
            <section className={commonStyle.marginBottom}>
                <DescriptionList titleWidth="200px">
                    <DescriptionTerm>Navn på ansvarlig søker:</DescriptionTerm>
                    <DescriptionDetails>{samtykkeTiltakshaver.AnsvarligSoeker}</DescriptionDetails>
                    <DescriptionTerm>Navn på tiltakshaver:</DescriptionTerm>
                    <DescriptionDetails>{samtykkeTiltakshaver.TiltakshaverNavn}</DescriptionDetails>
                    {samtykkeTiltakshaver.Prosjektnavn?.length ? (
                        <Fragment>
                            <DescriptionTerm>Prosjektnavn:</DescriptionTerm>
                            <DescriptionDetails>{samtykkeTiltakshaver.Prosjektnavn}</DescriptionDetails>
                        </Fragment>
                    ) : null}
                    {samtykkeTiltakshaver.TiltakType?.length ? (
                        <Fragment>
                            <DescriptionTerm>
                                {samtykkeTiltakshaver.TiltakType?.length === 1 ? "Tiltakstype:" : "Tiltakstyper:"}
                            </DescriptionTerm>
                            <DescriptionDetails>{renderTiltakstyper(samtykkeTiltakshaver)}</DescriptionDetails>
                        </Fragment>
                    ) : null}
                    {!!samtykkeTiltakshaver?.Byggested?.length ? (
                        <Fragment>
                            <DescriptionTerm>Adresse:</DescriptionTerm>
                            <DescriptionDetails>{formatAddress(samtykkeTiltakshaver?.Byggested[0])}</DescriptionDetails>
                        </Fragment>
                    ) : null}
                </DescriptionList>
            </section>

            <section className={commonStyle.marginBottom}>
                <Header size={3} htmlTag="h2" content="Søknad med vedlegg" />
                <p className={commonStyle.noMarginTop}>Forsikre deg om at du leser alle dokumentene</p>
                {renderSoknadList()}
                {renderVedleggList()}
            </section>

            <section className={commonStyle.marginBottom}>
                <Header size={3} htmlTag="h2" content="Som tiltakshaver har du" />
                <List>
                    <ListItem>Hovedansvaret for det som skal bygges.</ListItem>
                    <ListItem>
                        Ansvar for å finne en ny ansvarlig søker hvis den du har ikke lenger skal ha ansvaret for
                        byggesøknaden din.
                    </ListItem>
                    <ListItem>Ansvar for å betale byggesaksgebyr til kommunen.</ListItem>
                    <ListItem>Ansvar for å iverksette uavhengig kontroll dersom dette kreves.</ListItem>
                </List>
            </section>

            <section className={commonStyle.marginBottom}>
                <CheckBoxInput
                    id="hasSamtykke"
                    type="checkbox"
                    checked={hasSamtykke}
                    onChange={toggleHasSamtykke}
                    required
                    hasErrors={hasSamtykkeIsValidated && !hasSamtykke}
                    aria-describedby={
                        hasSamtykkeIsValidated && !hasSamtykke ? "hasSamtykke-errorMessage" : "hasSamtykke-description"
                    }
                >
                    <span>
                        Jeg har lest byggesøknaden med vedlegg, og samtykker til at ansvarlig søker kan sende
                        byggesøknaden videre til kommunen for saksbehandling.
                    </span>
                </CheckBoxInput>
                <p id="hasSamtykke-description">
                    Når du trykker SEND SAMTYKKE, signeres ditt samtykke til byggeplanene, og sendes ansvarlig søker.
                    Dersom du signerer som privatperson, blir fødselsnummeret ditt også brukt i kommunens
                    saksbehandlingssystem.
                </p>
                <p>
                    <a
                        href={`${window?.location?.origin}/personvernerklaering`}
                        target="_blank"
                        title="Personvernerklæring"
                        rel="noopener noreferrer"
                    >
                        Les mer om hvordan vi behandler personopplysningene dine.
                    </a>
                </p>
                <div role="alert">
                    {hasSamtykkeIsValidated && !hasSamtykke ? (
                        <div className={`${commonStyle.marginBottomSmall} ${commonStyle.marginTop}`}>
                            <ErrorBox>
                                <h2>Du kan ikke sende samtykke før alle opplysningene er fylt ut:</h2>
                                <ul>
                                    <li id="hasSamtykke-errorMessage">
                                        Du må krysse av for at du har lest byggesøknaden med vedlegg, og samtykker til
                                        at ansvarlig søker kan sende byggesøknaden videre til kommunen for
                                        saksbehandling.
                                    </li>
                                </ul>
                            </ErrorBox>
                        </div>
                    ) : null}
                </div>
                
            </section>

            <div className={commonStyle.buttonRow}>
                <Button color="primary" onClick={handlePrevButtonClick} content="Tilbake" arrow="left" />
                <Button
                    color="primary"
                    onClick={handleSendSamtykkeClick}
                    content="Send samtykke"
                    disabled={sendingSamtykke}
                />
            </div>
        </Fragment>
    ) : null;
};

export default GiDittSamtykke;
