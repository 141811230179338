import { UPDATE_LOG_MESSAGES, CLEAR_LOG_MESSAGES } from "constants/types";

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LOG_MESSAGES:
            return action.payload;
        case CLEAR_LOG_MESSAGES:
            return action.payload;
        default:
            return state;
    }
};

export default reducer;
