// Types
import { UPDATE_WIZARD_STEPS } from 'constants/types';

export const updateWizardSteps = wizardSteps => {
    return {
        type: UPDATE_WIZARD_STEPS, payload: wizardSteps
    }
}


export const updateWizardStep = (wizardStepIndex, updatedWizardStep) => (dispatch, getState) => {
    const wizardSteps = getState()?.wizardSteps;
    const newWizardSteps = {
        ...wizardSteps,
        [wizardStepIndex]: {
            ...wizardSteps[wizardStepIndex],
            ...updatedWizardStep
        }
    }
    dispatch(updateWizardSteps(newWizardSteps))
}

export const validateWizardSteps = (activeWizardStepIndex) => (dispatch, getState) => {
    const state = getState();
    const availableReportees = state.availableReportees;
    const selectedReportee = state.selectedReportee;
    const attachmentsTransferComplete = state.attachmentsTransferComplete;
    const archiveReference = state.archiveReference;
    const wizardSteps = state.wizardSteps;

    const samtykkeTilByggeplanerIndex = 'samtykke-til-byggeplaner';
    const velgTiltakshaverIndex = 'velg-tiltakshaver';
    const giDittSamtykkeIndex = 'gi-ditt-samtykke';
    const kvitteringIndex = 'kvittering';

    const validations = {
        [samtykkeTilByggeplanerIndex]: () => {
            if (wizardSteps[samtykkeTilByggeplanerIndex].finished || wizardSteps[samtykkeTilByggeplanerIndex].hasErrors) {
                dispatch(updateWizardStep(samtykkeTilByggeplanerIndex, { finished: false, hasErrors: false }));
            }
            if (wizardSteps[velgTiltakshaverIndex].finished || wizardSteps[velgTiltakshaverIndex].hasErrors) {
                dispatch(updateWizardStep(velgTiltakshaverIndex, { finished: false, hasErrors: false }));
            }
            if (wizardSteps[giDittSamtykkeIndex].finished || wizardSteps[giDittSamtykkeIndex].hasErrors) {
                dispatch(updateWizardStep(giDittSamtykkeIndex, { finished: false, hasErrors: false }));
            }
            if (wizardSteps[kvitteringIndex].finished || wizardSteps[kvitteringIndex].hasErrors) {
                dispatch(updateWizardStep(kvitteringIndex, { finished: false, hasErrors: false }));
            }
        },
        [velgTiltakshaverIndex]: () => {
            if (availableReportees) {
                if (!wizardSteps[samtykkeTilByggeplanerIndex].finished || wizardSteps[samtykkeTilByggeplanerIndex].hasErrors) {
                    dispatch(updateWizardStep(samtykkeTilByggeplanerIndex, { finished: true, hasErrors: false }));
                }
            } else {
                if (wizardSteps[samtykkeTilByggeplanerIndex].finished || !wizardSteps[samtykkeTilByggeplanerIndex].hasErrors) {
                    dispatch(updateWizardStep(samtykkeTilByggeplanerIndex, { finished: false, hasErrors: true }));
                }
            }

            if (wizardSteps[velgTiltakshaverIndex].finished || wizardSteps[velgTiltakshaverIndex].hasErrors) {
                dispatch(updateWizardStep(velgTiltakshaverIndex, { finished: false, hasErrors: false }));
            }
            if (wizardSteps[giDittSamtykkeIndex].finished || wizardSteps[giDittSamtykkeIndex].hasErrors) {
                dispatch(updateWizardStep(velgTiltakshaverIndex, { finished: false, hasErrors: false }));
            }
            if (wizardSteps[kvitteringIndex].finished || wizardSteps[kvitteringIndex].hasErrors) {
                dispatch(updateWizardStep(kvitteringIndex, { finished: false, hasErrors: false }));
            }
        },
        [giDittSamtykkeIndex]: () => {
            if (selectedReportee) {
                if (!wizardSteps[velgTiltakshaverIndex].finished || wizardSteps[velgTiltakshaverIndex].hasErrors) {
                    dispatch(updateWizardStep(velgTiltakshaverIndex, { finished: true, hasErrors: false }));
                }
            } else {
                if (wizardSteps[velgTiltakshaverIndex].finished || !wizardSteps[velgTiltakshaverIndex].hasErrors) {
                    dispatch(updateWizardStep(velgTiltakshaverIndex, { finished: false, hasErrors: true }));
                }
            }
            if (wizardSteps[giDittSamtykkeIndex].finished || wizardSteps[giDittSamtykkeIndex].hasErrors) {
                dispatch(updateWizardStep(velgTiltakshaverIndex, { finished: false, hasErrors: false }));
            }
            if (wizardSteps[kvitteringIndex].finished || wizardSteps[kvitteringIndex].hasErrors) {
                dispatch(updateWizardStep(kvitteringIndex, { finished: false, hasErrors: false }));
            }
        },
        [kvitteringIndex]: () => {
            if (attachmentsTransferComplete && archiveReference?.length) {
                if (!wizardSteps[giDittSamtykkeIndex].finished || wizardSteps[giDittSamtykkeIndex].hasErrors) {
                    dispatch(updateWizardStep(giDittSamtykkeIndex, { finished: true, hasErrors: false }));
                }
            } else {
                if (wizardSteps[giDittSamtykkeIndex].finished || !wizardSteps[giDittSamtykkeIndex].hasErrors) {
                    dispatch(updateWizardStep(giDittSamtykkeIndex, { finished: false, hasErrors: true }));
                }
            }
        }
    }

    validations?.[activeWizardStepIndex]?.();

}



