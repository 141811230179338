// Dependencies
import { useDispatch, useSelector } from 'react-redux';

// DIBK Design
import { Dialog, Header, ProgressBar } from 'dibk-design';

// Actions
import { updateShowAttachmentStatusModal } from 'actions/ShowAttachmentsStatusActions';

// Stylesheets
import style from 'components/partials/AttachmentsStatusModal.module.scss';

const AttachmentsStatusModal = () => {

    const dispatch = useDispatch();

    // Redux store
    const attachmentsStatus = useSelector(state => state.attachmentsStatus);
    const showAttachmentsStatusModal = useSelector(state => state.showAttachmentsStatusModal);

    const handleOutsideClick = () => {
        dispatch(updateShowAttachmentStatusModal(false));
    }
    
    const hasErrors = attachmentsStatus.some(attachmentStatus => {
        return attachmentStatus?.errorMessages?.length
    })

    return attachmentsStatus?.length && showAttachmentsStatusModal
        ? (<div>
            <Dialog modal onClickOutside={handleOutsideClick} closeButton maxWidth="960px">
                <Header content={hasErrors ? 'Feil ved signering av samtykke' : 'Laster opp vedlegg'} size={3} htmlTag="h2" />
                <div>
                    {
                        attachmentsStatus.map((attachmentStatus, attachmentIndex) => {
                            const downloadProgress = attachmentStatus?.downloadProgress || 0;
                            const uploadProgress = attachmentStatus?.uploadProgress || 0;
                            const progress = Math.round((downloadProgress + uploadProgress) / 2);
                            return (
                                <div className={style.attachmentListItem} key={attachmentIndex}>
                                    <ProgressBar progress={progress} hasErrors={!!attachmentStatus?.errorMessages?.length} />
                                    <div className={style.attachmentListItemText}>
                                        <div>{attachmentStatus?.attachment?.Navn}</div>
                                        {
                                            attachmentStatus?.errorMessages?.length
                                                ? (<div className={style.attachmentListItemErrorMessage}>{attachmentStatus?.errorMessages}</div>)
                                                : ''
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </Dialog>
        </div>)
        : '';
}

export default AttachmentsStatusModal;
