// Types
import { UPDATE_EMAIL_RECEIPT_IS_SENT } from "constants/types";

//Helpers
import { renderEmailSubject, renderEmailContent } from "helpers/emailHelpers";
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers";

const updateEmailReceiptIsSent = (emailReceiptIsSent) => (dispatch) => {
    dispatch({ type: UPDATE_EMAIL_RECEIPT_IS_SENT, payload: emailReceiptIsSent });
};

export const sendReceiptEmail = (samtykkeTiltakshaver, archiveReference, emailAddress) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;

    const internalApiUrl = `${getEnvironmentVariable("internalServerUrl")}/api`;
    const apiUrl = `${internalApiUrl}/internal/samtykketiltakshaver/${samtykkeTiltakshaver?.Id}/receipt`;

    const postData = {
        Subject: renderEmailSubject(samtykkeTiltakshaver),
        HtmlBody: renderEmailContent(samtykkeTiltakshaver, archiveReference),
        ToEmailAddress: emailAddress
    };

    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, fetchOptions)
        .then((response) => {
            if (response.ok) {
                dispatch(updateEmailReceiptIsSent(true));
            } else {
                const messageDescription = "Failed to send receipt email";
                const logMessage = {
                    level: "Warning",
                    message: response?.reponseText?.length
                        ? `${response.reponseText} | ${messageDescription}`
                        : messageDescription,
                    statuscode: response?.status,
                    path: response?.url
                };
                clientLogger.postLogData([logMessage]);
            }
        })
        .catch((error) => {
            const logMessage = {
                level: "Error",
                path: apiUrl,
                message: "Failed to send receipt email"
            };
            clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                clientLogger.postLogData([logMessage]);
            });
        });
};
