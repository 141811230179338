// Dependencies
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

// DIBK Design
import { Button, ErrorBox, Header } from "dibk-design";

// Partials
import ReporteesList from "components/partials/ReporteesList";
import MainHeading from "components/partials/MainHeading";

// Actions
import { fetchAvailableReportees, updateAvailableReportees, updateSelectedReportee } from "actions/ReporteesActions";
import { validateWizardSteps } from "actions/WizardStepsActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";

const VelgTiltakshaver = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Url parameters
    const guid = params?.guid || null;

    // Redux store
    const accessToken = useSelector((state) => state.oidc?.user?.access_token);
    const isLoadingUser = useSelector((state) => state.oidc?.isLoadingUser);
    const availableReportees = useSelector((state) => state.availableReportees);
    const selectedReportee = useSelector((state) => state.selectedReportee);
    const samtykkeTiltakshaver = useSelector((state) => state.samtykkeTiltakshaver);

    // State
    const [selectedReporteeIsValidated, setSelectedReporteeIsValidated] = useState();
    const [isFetchingReportees, setIsFetchingReportees] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (samtykkeTiltakshaver && samtykkeTiltakshaver.Status !== "Opprettet") {
            navigate(`/veiviser/${guid}/samtykke-til-byggeplaner`);
        } else {
            if (!availableReportees?.length && !isLoadingUser && !isFetchingReportees) {
                if (accessToken) {
                    setIsFetchingReportees(true);
                    dispatch(fetchAvailableReportees()).then(() => {
                        setIsFetchingReportees(false);
                        dispatch(validateWizardSteps("velg-tiltakshaver"));
                    });
                } else {
                    dispatch(validateWizardSteps("velg-tiltakshaver"));
                }
            }
            if (availableReportees?.length) {
                dispatch(validateWizardSteps("velg-tiltakshaver"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [samtykkeTiltakshaver, guid, availableReportees, accessToken, isLoadingUser, navigate, dispatch]);

    useEffect(() => {
        if (samtykkeTiltakshaver && availableReportees && !selectedReportee) {
            const samtykkeTiltakshaverReportee = samtykkeTiltakshaver?.TiltakshaverOrgnr
                ? availableReportees.find((availableReportee) => {
                      return samtykkeTiltakshaver.TiltakshaverOrgnr === availableReportee.OrganizationNumber;
                  })
                : null;
            if (samtykkeTiltakshaverReportee) {
                // Move samtykkeTiltakshaverReportee to top of array
                const newAvailableReportees = availableReportees.filter((availableReportee) => {
                    return (
                        samtykkeTiltakshaverReportee?.OrganizationNumber !== availableReportee?.OrganizationNumber ||
                        !availableReportee?.OrganizationNumber
                    );
                });
                newAvailableReportees.unshift(samtykkeTiltakshaverReportee);

                dispatch(updateAvailableReportees(newAvailableReportees));
                dispatch(updateSelectedReportee(samtykkeTiltakshaverReportee));
            }
        }
    }, [availableReportees, selectedReportee, samtykkeTiltakshaver, dispatch]);

    const handlePrevButtonClick = () => {
        dispatch(renewLastInteractionAt());
        navigate(`/veiviser/${guid}/samtykke-til-byggeplaner`);
    };

    const handleNextButtonClick = () => {
        dispatch(renewLastInteractionAt());
        setSelectedReporteeIsValidated(true);
        if (selectedReportee) {
            navigate(`/veiviser/${guid}/gi-ditt-samtykke`);
        }
    };

    return samtykkeTiltakshaver ? (
        <Fragment>
            <MainHeading
                id="step-heading"
                pageHeading="Velg hvem du vil representere i byggesaken"
                pageTitle="Velg hvem du vil representere i byggesaken - Tiltakshavers samtykke til byggeplaner"
            />
            <p>Under ser du hvem du kan signere på vegne av.</p>
            <p>
                Ansvarlig søker har oppgitt {samtykkeTiltakshaver.TiltakshaverNavn}
                {samtykkeTiltakshaver?.TiltakshaverOrgnr?.length
                    ? ` (org.nr. ${samtykkeTiltakshaver.TiltakshaverOrgnr})`
                    : ""}{" "}
                som tiltakshaver for byggeprosjektet.
            </p>
            <p>
                Hvis ansvarlig søker har oppgitt feil navn, må du ta kontakt med ansvarlig søker og logge ut uten å
                signere samtykket.
            </p>
            {availableReportees?.length ? (
                <Fragment>
                    <p>
                        Hvis du ikke finner riktig tiltakshaver i listen under, mangler du kanskje rettigheter i Altinn.
                        <br />
                        <a href="https://dibk.no/rettigheter-i-altinn" target="_blank" rel="noopener noreferrer">
                            Se veiviseren for rettigheter til byggesak i Altinn
                        </a>
                        .
                    </p>

                    <ReporteesList
                        reportees={availableReportees}
                        tiltakshaverPartstypeKode={samtykkeTiltakshaver.TiltakshaverPartstypeKode}
                        hasErrors={selectedReporteeIsValidated && !selectedReportee}
                    />
                </Fragment>
            ) : null}

            <div role="alert">
                {!availableReportees?.length ? (
                    <div className={commonStyle.marginBottomSmall}>
                        <ErrorBox>
                            Det ser ut til at du mangler rettigheter til å gi samtykke for{" "}
                            {samtykkeTiltakshaver.TiltakshaverNavn}.
                            <br />
                            Se{" "}
                            <a href="https://dibk.no/rettigheter-i-altinn" target="_blank" rel="noopener noreferrer">
                                veiviseren for rettigheter til byggesaker i Altinn
                            </a>{" "}
                            for å tildele riktige rettigheter.
                        </ErrorBox>
                    </div>
                ) : null}

                {selectedReporteeIsValidated && !selectedReportee ? (
                    <div className={commonStyle.marginBottomSmall}>
                        <ErrorBox>
                            <div>
                                <Header size={2}>Du kan ikke gå videre før alle opplysningene er fylt ut:</Header>
                                <ul>
                                    <li id="selectedReportee-errorMessage">
                                        Du må velge tiltakshaver for å gå videre.
                                    </li>
                                </ul>
                            </div>
                        </ErrorBox>
                    </div>
                ) : null}
            </div>
            <div className={commonStyle.buttonRow}>
                <Button color="primary" onClick={handlePrevButtonClick} content="Tilbake" arrow="left" />
                <Button
                    color="primary"
                    disabled={!availableReportees?.length}
                    onClick={handleNextButtonClick}
                    content="Neste"
                    arrow="right"
                />
            </div>
        </Fragment>
    ) : null;
};

export default VelgTiltakshaver;
