import { UPDATE_WIZARD_STEPS } from 'constants/types';

const initialState = {
    'samtykke-til-byggeplaner': {
        id: 'samtykke-til-byggeplaner',
        name: 'Samtykke til byggeplaner',
        finished: false,
        hasErrors: false
    },
    'velg-tiltakshaver': {
        id: 'velg-tiltakshaver',
        name: 'Velg tiltakshaver',
        finished: false,
        hasErrors: false
    },
    'gi-ditt-samtykke': {
        id: 'gi-ditt-samtykke',
        name: 'Gi ditt samtykke',
        finished: false,
        hasErrors: false
    },
    'kvittering': {
        id: 'kvittering',
        name: 'Kvittering',
        finished: false,
        hasErrors: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_WIZARD_STEPS:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;
