// Dependecies
import { useState, useEffect, Fragment } from "react";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { Route, Routes } from "react-router";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { HelmetProvider } from "react-helmet-async";

// Utils
import configureStore, { history } from "utils/configureStore";
import userManagerPromise from "utils/userManager";

// Actions
import { updateClientLogger } from "actions/ClientLoggerActions";

// Routes
import About from "components/routes/About";
import Wizard from "components/routes/Wizard";
import WizardInfo from "components/routes/WizardInfo";
import Personvernerklaering from "components/routes/Personvernerklaering";
import ErklaeringOmInformasjonskapsler from "components/routes/ErklaeringOmInformasjonskapsler";
import NotFound from "components/routes/NotFound";
import OidcCallback from "components/routes/OidcCallback";
import OidcSignoutCallback from "components/routes/OidcSignoutCallback";

// Partials
import MainNavigationBar from "components/partials/MainNavigationBar";
import FooterContainer from "components/partials/FooterContainer";
import StaySignedInDialog from "components/partials/StaySignedInDialog";

const initialState = {};
const storePromise = configureStore(initialState, userManagerPromise);
let store = null;
let userManager = null;

const App = ({ clientLogger }) => {
    // State
    const [storeIsLoaded, setStoreIsLoaded] = useState(false);
    const [userManagerIsLoaded, setUserManagerIsLoaded] = useState(false);

    useEffect(() => {
        storePromise.then((storeConfig) => {
            store = storeConfig;
            setStoreIsLoaded(true);
            store.dispatch(updateClientLogger(clientLogger));
        });
        userManagerPromise.then((userManagerConfig) => {
            userManager = userManagerConfig;
            setUserManagerIsLoaded(true);
        });
    }, [clientLogger]);

    if (userManager && userManagerIsLoaded && storeIsLoaded) {
        return (
            <Provider store={store}>
                <OidcProvider userManager={userManager} store={store}>
                    <HelmetProvider>
                        <MainNavigationBar userManager={userManager} />
                        <StaySignedInDialog userManager={userManager} />
                        <Router history={history}>
                            {
                                <Fragment>
                                        <main id="main-content">
                                            <Routes>
                                                <Route
                                                    exact
                                                    path="/signin-oidc"
                                                    element={<OidcCallback userManager={userManager} />}
                                                />
                                                <Route
                                                    exact
                                                    path="/signout-callback-oidc"
                                                    element={<OidcSignoutCallback userManager={userManager} />}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/veiviser/:guid/:stepId"
                                                    element={<Wizard userManager={userManager} />}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/veiviser/:guid"
                                                    element={<Wizard userManager={userManager} />}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/veiviser"
                                                    element={<WizardInfo userManager={userManager} />}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/cookieerklaering"
                                                    element={<ErklaeringOmInformasjonskapsler />}
                                                />
                                                <Route
                                                    exact={true}
                                                    path="/personvernerklaering"
                                                    element={<Personvernerklaering />}
                                                />
                                                <Route exact={true} path="/" element={<About />} />
                                                <Route
                                                    exact={true}
                                                    key={"/silent-refresh.html"}
                                                    path="/silent-refresh.html"
                                                    element={() => null}
                                                />
                                                <Route path="*" element={<NotFound />} />
                                            </Routes>
                                        </main>
                                
                                    <FooterContainer />
                                </Fragment>
                            }
                        </Router>
                    </HelmetProvider>
                </OidcProvider>
            </Provider>
        );
    } else return "";
};

export default App;
