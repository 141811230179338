// Dependencies
import React from "react";

// DIBK Design
import { Footer } from "dibk-design";

const FooterContainer = () => {
    return (
        <Footer>
            <p>
                Har du spørsmål?
                <br />
                <a href="https://dibk.no/tiltakshavers-samtykke" target="_blank" rel="noopener noreferrer">
                    Se vanlig stilte spørsmål og svar
                </a>
            </p>
            <p>
                <a
                    href={`${window?.location?.origin}/personvernerklaering`}
                    target="_blank"
                    title="Personvernerklæring"
                    rel="noopener noreferrer"
                >
                    Personvernerklæring
                </a>
                <a
                    href={`${window?.location?.origin}/cookieerklaering`}
                    target="_blank"
                    title="Erklæring om informasjonskapsler"
                    rel="noopener noreferrer"
                >
                    Erklæring om informasjonskapsler
                </a>
                 <a
                    href="https://uustatus.no/nb/erklaringer/publisert/a38c41d3-533b-4ba1-a266-9ee7077663d7"
                    target="_blank"
                    title="Tilgjengelighetserklæring"
                    rel="noopener noreferrer"
                >
                    Tilgjengelighetserklæring
                </a>
            </p>
        </Footer>
    );
};

export default FooterContainer;
