// Helpers
import { getProsjektnavn } from "helpers/samtykkeTiltakshaverHelpers";
import { getAltinnInboxUrl } from "helpers/urlHelpers";

export const validateEmailField = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.exec(email) !== null;
}

export const renderEmailSubject = (samtykkeTiltakshaver) => {
    return `Samtykke til byggeplaner er sendt for ${getProsjektnavn(samtykkeTiltakshaver)}`
};

export const renderEmailContent = (samtykkeTiltakshaver, archiveReference) => {
    const ansvarligSoeker = samtykkeTiltakshaver?.AnsvarligSoeker?.length ? samtykkeTiltakshaver.AnsvarligSoeker : 'ansvarlig søker';
    return `
        <p>
            Samtykket er sendt til ${ansvarligSoeker}. Kopi av samtykket og søknaden med vedlegg finner du i ditt arkiv i Altinn, med referanse ${archiveReference}.
        </p>
        <p>
            <a href="${getAltinnInboxUrl()}">Trykk her for å se arkivet ditt i Altinn.</a>
        </p>
        <p>
            Med vennlig hilsen </br>
            Direktoratet for byggkvalitet på vegne av ${ansvarligSoeker}
        </p>
    `;
};