import { UPDATE_HAS_SAMTYKKE } from 'constants/types';

const initialState = false;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HAS_SAMTYKKE:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;
