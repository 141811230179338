// Types
import { FETCH_TILTAKSHAVER_SIGNATUR_XML } from 'constants/types';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

export const fetchTiltakshaverSignaturXml = (guid) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;

    const internalApiUrl = `${getEnvironmentVariable('internalServerUrl')}/api`;
    const apiUrl = `${internalApiUrl}/internal/samtykketiltakshaver/${guid}/form`
    const bearerToken = `Bearer ${accessToken}`;

    const fetchOptions = {
        headers: {
            'Authorization': bearerToken,
            'Accept': 'application/xml'
        }
    };

    return fetch(apiUrl, fetchOptions).then(res => res.text()).then(tiltakshaverSignaturXml => {
        const logMessage = {
            level: "Debug",
            path: apiUrl,
            message: "Fetched XML for SamtykkeTiltakshaver"
        };
        clientLogger.postLogData([logMessage]);
        dispatch({
            type: FETCH_TILTAKSHAVER_SIGNATUR_XML, payload: tiltakshaverSignaturXml
        });
        return tiltakshaverSignaturXml;
    }).catch(error => {
        const logMessage = {
            level: "Error",
            path: apiUrl,
            message: "Failed to fetch XML document for TiltakshaverSignatur"
        };
        clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
            clientLogger.postLogData([logMessage]);
        });
    });
}
