// Dependencies
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// DIBK Design
import { Button, Dialog, Header } from "dibk-design";

// Actions
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Selectors
import { getSecondsSinceLastInteraction } from "reducers/LastInteractionAtReducer";

// Stylesheets
import commonStyle from "components/common.module.scss";

const StaySignedInDialog = ({ userManager }) => {
    const dispatch = useDispatch();

    // Redux store
    const secondsSinceLastInteraction = useSelector((state) => getSecondsSinceLastInteraction(state));
    const user = useSelector((state) => state.oidc?.user);

    const showDialog = secondsSinceLastInteraction > 15 * 60; // 15 minutes
    const autoLogOut = secondsSinceLastInteraction > 20 * 60; // 20 minutes

    const handleOutsideClick = () => {
        return false;
    };

    const renewToken = () => {
        userManager
            .signinSilent()
            .then((u) => {
                dispatch(renewLastInteractionAt());
            })
            .catch((er) => {
                console.log(er);
            });
    };

    useEffect(() => {
        if (autoLogOut) {
            userManager.signoutRedirect({
                id_token_hint: user?.id_token
            });
            userManager.removeUser();
        }
    }, [autoLogOut, userManager, user]);

    return showDialog ? (
        <Dialog modal onClickOutside={handleOutsideClick} maxWidth="725px">
            <Header content="Er du fortsatt her?" size={2} />
            <div className="modal-content">
                <p>Om du ikke er her vil du snart bli logget ut av sikkerhetsmessige hensyn.</p>
                <div className={commonStyle.buttonRow}>
                    <Button color="primary" content="Jeg er her" onClick={renewToken} />
                </div>
            </div>
        </Dialog>
    ) : (
        ""
    );
};

export default StaySignedInDialog;
