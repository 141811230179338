// Dependencies
import React, { useEffect } from "react";

// DIBK Design
import { Container, Header, List, ListItem, Paper, Table } from "dibk-design";

// Partials
import MainHeading from "components/partials/MainHeading";

// Helpers
import { classNameArrayToClassNameString, scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";

const Personvernerklaering = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container>
            <Paper>
                <MainHeading
                    pageHeading="Personvernerklæring"
                    pageTitle="Personvernerklæring - Tiltakshavers samtykke til byggeplaner"
                />
                <span className={classNameArrayToClassNameString([commonStyle.subtitle, commonStyle.marginBottom])}>
                    <i>Sist oppdatert 30.05.2023</i>
                </span>
                <Header
                    content="Behandling av personopplysninger i Tiltakshavers samtykke til byggeplaner"
                    size={2}
                ></Header>
                <p className={commonStyle.noMarginTop}>
                    Direktoratet for byggkvalitet (DiBK) eier og driver tjenesten Tiltakshavers samtykke til
                    byggeplaner, som en del av Fellestjenester BYGG. For å kunne tilby tjenesten, er vi nødt til å
                    behandle noen personopplysninger om deg som bruker tjenesten (ofte kalt «den registrerte») i rollen
                    som tiltakshaver.
                </p>
                <Header content="Slik fungerer tjenesten" size={3}></Header>
                <List ordered>
                    <ListItem>
                        Ansvarlig søker velger søknadsløsningen til en av{" "}
                        <a
                            href="https://dibk.no/verktoy-og-veivisere/andre-fagomrader/fellestjenester-bygg/tjenestene/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            våre godkjente leverandører
                        </a>{" "}
                        og klargjør byggesøknaden der.
                    </ListItem>
                    <ListItem>
                        Når byggesøknaden er klar, sender ansvarlige søker en unik lenke til tiltakshaver. Lenken går
                        til Tiltakshavers samtykke til byggeplaner.
                    </ListItem>
                    <ListItem>
                        Tiltakshaver logger seg inn i tjenesten med ID-porten, ser gjennom byggesøknaden og samtykker
                        til byggeplanene.
                    </ListItem>
                    <ListItem>
                        Samtykket blir tilgjengelig for ansvarlig søker i søknadsløsningen som er valgt. Det er
                        ansvarlig søker sitt ansvar å sende inn samtykket til kommunen sammen med byggesøknaden.
                    </ListItem>
                    <ListItem>
                        Dersom du har behov for det, kan du finne igjen samtykket med søknad og vedlegg i arkivet ditt i
                        Altinn.
                    </ListItem>
                </List>
                <Header content="Behandlingsansvarlig og behandlingsgrunnlag" size={3}></Header>
                <p>
                    DiBK er behandlingsansvarlig for personopplysningene som behandles av oss gjennom tjenesten
                    Tiltakshavers samtykke til byggeplaner. Det betyr at vi er ansvarlig for å behandle
                    personopplysninger på en{" "}
                    <a
                        href="https://www.datatilsynet.no/rettigheter-og-plikter/personvernprinsippene/grunnleggende-personvernprinsipper/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        lovlig, rettferdig og gjennomsiktig måte
                    </a>
                    .
                </p>
                <p>
                    Vi behandler personopplysningene dine for å kunne drifte tjenesten på en sikker måte, og for å utøve
                    våre lovpålagte plikter som direktorat, nasjonalt kompetansesenter på bygningsområdet og sentral
                    myndighet innenfor flere områder av plan- og bygningsloven. DiBK har rettslig grunnlag etter
                    personvernforordningen («GDPR») artikkel 6 nr. 1 e). Artikkelen sier at behandlingen er nødvendig
                    for å utføre en oppgave i allmenhetens interesse eller utøve offentlig myndighet som den
                    behandlingsansvarlige er pålagt.
                </p>
                <p>
                    I henhold til forvaltningsloven og eForvaltningsforskriften er elektronisk kommunikasjon
                    hovedregelen når vi kommuniserer med publikum. Vi behandler personopplysninger for å kunne tilby
                    Tiltakshavers samtykke til byggeplaner slik tjenesten er beskrevet over.
                </p>
                <Header content="Disse opplysningene behandler vi" size={3} />
                <List ordered>
                    <ListItem>Byggesøknaden med vedlegg.</ListItem>
                    <ListItem>Tiltakshavers navn og fødselsnummer, dersom tiltakshaver er en privatperson.</ListItem>
                    <ListItem>Tiltakshavers navn og organisasjonsnummer, dersom tiltakshaver er et foretak.</ListItem>
                    <ListItem>
                        Filer med tiltakshavers samtykke dersom det har blitt signert. Samtykket inneholder
                        tiltakshavers navn, samt adresse og tiltakstype for byggeprosjektet.
                    </ListItem>
                    <ListItem>Tiltakshavers e-postadresse, dersom vedkommende ber om kvittering på e-post.</ListItem>
                </List>
                <Header content="Så lenge lagrer vi opplysningene" size={3} />
                <p>
                    DiBK sletter alle personopplysninger når det har gått 12 måneder siden forespørselen om
                    tiltakshavers samtykke ble sendt fra ansvarlig søker. Informasjonen slettes uavhengig av om
                    samtykket har blitt signert eller ikke.
                </p>
                <p>E-postkvitteringer som sendes fra tjenesten slettes fra vår mailserver etter 60 dager.</p>
                <p>
                    Dokumentasjon som lagres i Altinn slettes ikke automatisk. Samtykket vil du finne igjen i arkivet
                    ditt.
                </p>
                <Header content="Slik deler vi dataene med våre samarbeidspartnere" size={3} />
                <p>
                    Personopplysningene vi behandler deles kun med aktører og samarbeidspartnere på bakgrunn av en
                    avtale eller en lovpålagt plikt. De leverandørene som behandler personopplysninger på vegne av oss,
                    har vi inngått en databehandleravtale med. I databehandleravtalen sikrer vi at personopplysningene
                    dine blir behandlet etter GDPR-regelverket. Vi setter tydelige rammer for hvordan
                    samarbeidspartneren kan behandle opplysningene, hva som er våre plikter og hvilke plikter
                    samarbeidspartneren har.
                </p>
                <p>
                    Tiltakshavers samtykke til byggeplaner har system-til-system-integrasjon. Det betyr at det kun er
                    ansatte som har tjenstlig behov for det, som har tilgang til personopplysningene dine. Den
                    elektroniske overføringen (HTTPS-kommunikasjonen) er alltid kryptert mellom de ulike
                    systemmottakerne.
                </p>

                <Header content="Søknadssystemet" size={4} />
                <p>
                    Når du samtykker til byggeplanene, blir samtykket tilgjengelig i søknadssystemet som ansvarlig søker
                    bruker. Samtykket inneholder tiltakshavers navn, samt adresse og tiltakstype for byggeprosjektet.
                    Fødselsnummeret blir ikke tilgjengelig for ansvarlig søker eller søknadssystemet.
                </p>
                <Header content="Kommunen" size={4} />
                <p>
                    Når ansvarlig søker sender samtykket sammen med byggesøknaden til kommunen, blir opplysningene med
                    til kommunen som skal saksbehandle søknaden. Dersom tiltakshaver er en privatperson, vil
                    fødselsnummeret bli dekryptert og sendt til kommunen slik at det kan brukes i saksbehandlingen.
                    Fødselsnummeret brukes til kommunikasjon om byggesaken, herunder gebyrkreving, og skjer i samsvar
                    med eForvaltningsforskriftens §§ 8 og 9.
                </p>

                <Header content="Oversikt over databehandlere" size={4} />
                <p>
                    DiBK bruker den nasjonale felleskomponenten ID-porten for innlogging på våre tjenester. ID-porten
                    leveres av Digitaliseringsdirektoratet, og du kan{" "}
                    <a href="https://eid.difi.no/nb/sikkerhet-og-personvern" target="_blank" rel="noopener noreferrer">
                        lese om hvordan de jobber med sikkerhet og personvern på denne siden
                    </a>
                    . Vi bruker Altinn til distribusjon og lagring av Tiltakshavers samtykke til byggeplaner. Dette er
                    også en tjeneste fra Digitaliseringsdirektoratet.{" "}
                    <a href="https://www.altinn.no/om-altinn/personvern/" target="_blank" rel="noopener noreferrer">
                        Les om hvordan Altinn behandler personopplysninger på denne siden
                    </a>
                    .
                </p>
                <Table>
                    <caption>I tillegg har DiBK følgende databehandlere:</caption>
                    <thead>
                        <tr>
                            <th>Databehandlere</th>
                            <th>Beskrivelse av rolle og personvernerklæring</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Arkitektum AS</td>
                            <td>
                                Utviklingsleverandør og leverandør av applikasjonsdrift.{" "}
                                <a
                                    href="https://arkitektum.no/personvernerklaering/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Personvernerklæring
                                </a>
                                .
                            </td>
                        </tr>
                        <tr>
                            <td>IVER AS</td>
                            <td>
                                Leverandør av teknisk serverdrift.{" "}
                                <a
                                    href="https://www.iver.com/en/privacy-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Personvernerklæring
                                </a>
                                .
                            </td>
                        </tr>
                        <tr>
                            <td>Microsoft Azure</td>
                            <td>
                                Leverandør av lagring og plattformtjenester.{" "}
                                <a
                                    href="https://privacy.microsoft.com/nb-no/privacystatement"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Personvernerklæring
                                </a>
                                .
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p className={commonStyle.marginTopSmall}>
                    Personopplysningene vil ikke bli brukt i noen andre sammenhenger. Vi selger, bytter eller
                    videreformidler ikke personopplysningene dine.
                </p>

                <Header content="Bruk av informasjonskapsler" size={3} />
                <p>
                    Tiltakshavers samtykke til byggeplaner bruker informasjonskapsler (cookies) for at tjenesten skal
                    fungere. Du kan lese mer om dette i{" "}
                    <a href={`${window?.location?.origin}/cookieerklaering`} title="Erklæring om informasjonskapsler">
                        erklæringen om informasjonskapsler
                    </a>
                    .
                </p>

                <Header content="Dine rettigheter" size={3} />
                <p>
                    Personopplysningsloven gir deg en rekke rettigheter når vi behandler personopplysningene dine. Du
                    har blant annet rett til å få innsyn i og informasjon om, hvilke personopplysninger vi behandler og
                    lagrer om deg. Hvis opplysningene vi har behandlet eller lagret er uriktige, har du rett til å få
                    dem korrigert. I særlige tilfeller kan du ha rett til å få opplysninger om deg slettet, innen
                    tidspunktet for de alminnelige generelle sletterutinene.
                </p>

                <p>
                    Hvis du har spørsmål om vår behandling, eller ønsker å utøve dine rettigheter som registrert, kan du
                    kontakte oss på <a href="mailto:ftb@dibk.no">ftb@dibk.no</a> så blir henvendelsen din videresendt
                    vårt personvernombud.
                </p>
                <p>
                    <a href="https://www.datatilsynet.no/" target="_blank" rel="noopener noreferrer">
                        Hvis du vil lese mer om personvern og rettighetene dine, anbefaler vi Datatilsynets nettsider
                    </a>
                    .
                </p>
            </Paper>
        </Container>
    );
};

export default Personvernerklaering;
