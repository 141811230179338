// Dependencies
import React from "react";
import ReactDOM from "react-dom/client";

// Components
import App from "App";
import ConfigLoader from "components/ConfigLoader";

// Utils
import * as serviceWorker from "./serviceWorker";

// Stylesheets
import "index.scss";

const sourceMapUrl = `${document.currentScript.src}.map`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ConfigLoader
            sourceMapUrl={sourceMapUrl}
            ready={(config, clientLogger) => {
                return <App clientLogger={clientLogger} />;
            }}
        />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
