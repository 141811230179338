const convertFilesizeToLargerUnit = (fileSizeInfo) => {
    if (fileSizeInfo.filesize >= 1024) {
        fileSizeInfo.unitIndex += 1;
        fileSizeInfo.filesize = fileSizeInfo.filesize / 1024;
        convertFilesizeToLargerUnit(fileSizeInfo);
    }
    return fileSizeInfo;
}

export const formatFilesize = (filesize) => {
    const fileSizeInfo = convertFilesizeToLargerUnit({
        unitIndex: 0,
        filesize: filesize
    });
    const units = ["B", "kB", "MB", "GB", "TB"];
    const roundedFilesize = Math.round(fileSizeInfo.filesize * 100) / 100;
    const selectedUnit = units[fileSizeInfo.unitIndex];
    return `${roundedFilesize} ${selectedUnit}`;
}

export const formatAddress = addressObject => {
    if (!addressObject || !Object.keys(addressObject).length) {
        return null
    }
    const adresse = addressObject.Adresselinje1?.length ? addressObject.Adresselinje1 : null;
    const postNrSted = addressObject.Postnr?.length && addressObject.Poststed?.length ? `${addressObject.Postnr} ${addressObject.Poststed}` : null
    const kommunenavn = addressObject.Kommunenavn?.length ? `${addressObject.Kommunenavn} kommune` : null;
    const gnrBnrShort = addressObject.Gardsnr && addressObject.Bruksnr ? `(${addressObject.Gardsnr}/${addressObject.Bruksnr})` : null;
    const gnrBnrLong = addressObject.Gardsnr && addressObject.Bruksnr ? `Gårdsnr. ${addressObject.Gardsnr}, bruksnr. ${addressObject.Bruksnr}` : null;
    if (adresse && postNrSted) {
        return `${adresse}, ${postNrSted} ${gnrBnrShort ? gnrBnrShort : ''}`
    } else if (adresse) {
        return `${adresse}${kommunenavn ? `, ${kommunenavn}` : ''}${gnrBnrShort ? ` ${gnrBnrShort}` : ''}`
    } else if (postNrSted) {
        return `${gnrBnrLong ? `${gnrBnrLong}, ` : ''}${postNrSted}`
    } else if (gnrBnrLong || kommunenavn) {
        return `${gnrBnrLong ? `${gnrBnrLong}, ` : ''}${kommunenavn}`
    } else {
        return null
    }
}