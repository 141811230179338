// Types
import { FETCH_AVAILABLE_REPORTEES, UPDATE_AVAILABLE_REPORTEES, UPDATE_SELECTED_REPORTEE } from "constants/types";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

const fetchAltinnReportees =
    (prevFetchedReportees, pageNumber = 0) =>
    (dispatch, getState) => {
        pageNumber++;
        const accessToken = getState()?.oidc?.user?.access_token;
        const clientLogger = getState()?.clientLogger;
        const serviceCode = 5303;
        const serviceEdition = 1;
        const resultsPerPage = 50; // Maximum for Altinn is 50

        const serviceCodeParam = `&serviceCode=${serviceCode}`;
        const serviceEditionParam = `&serviceEdition=${serviceEdition}`;
        const resultsPerPageParam = `&$top=${resultsPerPage}`;
        const skipParam = prevFetchedReportees?.length ? `&$skip=${prevFetchedReportees.length}` : "";

        const altinnApiUrl = `${getEnvironmentVariable("altinnServerUrl")}/api`;
        const apiUrl = `${altinnApiUrl}/reportees?showConsentReportees=false${resultsPerPageParam}${skipParam}&$orderby=Type desc${serviceCodeParam}${serviceEditionParam}`;
        const apiKey = getEnvironmentVariable("altinnApiKey");

        const bearerToken = `Bearer ${accessToken}`;
        const fetchOptions = {
            method: "GET",
            headers: {
                Authorization: bearerToken,
                ApiKey: apiKey
            }
        };
        return new Promise((resolve, reject) => {
            let response;
            let logMessage;
            fetch(apiUrl, fetchOptions)
                .then((res) => {
                    response = res;
                    logMessage = {
                        statuscode: response?.status,
                        path: apiUrl
                    };
                    return res.json();
                })
                .then((newFetchedReportees) => {
                    if (!newFetchedReportees.length) {
                        const messageDescription = "No available Altinn reportees";
                        logMessage = {
                            ...logMessage,
                            level: "Warning",
                            message: response.reponseText?.length
                                ? `${response.reponseText} | ${messageDescription}`
                                : messageDescription
                        };
                        clientLogger.postLogData([logMessage]);
                    }
                    const allFetchedReportees = prevFetchedReportees
                        ? prevFetchedReportees.concat(newFetchedReportees)
                        : newFetchedReportees;
                    if (newFetchedReportees.length === resultsPerPage) {
                        const messageDescription = `${resultsPerPage} or more available Altinn reportee on page ${pageNumber}. Fetching additional Altinn reportees`;
                        logMessage = {
                            ...logMessage,
                            level: "Debug",
                            message: response.reponseText?.length
                                ? `${response.reponseText} | ${messageDescription}`
                                : messageDescription
                        };
                        clientLogger.postLogData([logMessage]);
                        resolve(dispatch(fetchAltinnReportees(allFetchedReportees, pageNumber)));
                    } else {
                        const messageDescription = `${allFetchedReportees.length} available Altinn reportee${
                            allFetchedReportees.length !== 1 ? "s" : ""
                        }`;
                        logMessage = {
                            ...logMessage,
                            level: "Debug",
                            message: response.reponseText?.length
                                ? `${response.reponseText} | ${messageDescription}`
                                : messageDescription
                        };
                        clientLogger.postLogData([logMessage]);
                        resolve({ reportees: allFetchedReportees, response });
                    }
                })
                .catch((error) => {
                    logMessage = {
                        level: "Error",
                        path: apiUrl,
                        message: "Failed to fetch Altinn reportees"
                    };
                    clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                        clientLogger.postLogData([logMessage]);
                    });
                    resolve(null);
                });
        });
    };

const reporteHasTiltakshaverPartstypeKode = (reportee, tiltakshaverPartstypeKode) => {
    switch (tiltakshaverPartstypeKode) {
        case "Foretak":
        case "Offentlig myndighet":
        case "Organisasjon":
            return ["Enterprise", "Foretak", "Føretak", "Business", "Bedrift"].includes(reportee.Type);
        case "Privatperson":
            return reportee.Type === "Person";
        default:
            return true;
    }
};

export const fetchAvailableReportees = () => (dispatch, getState) => {
    const clientLogger = getState()?.clientLogger;

    return dispatch(fetchAltinnReportees()).then(({ reportees, response }) => {
        const tiltakshaverPartstypeKode = getState()?.samtykkeTiltakshaver?.TiltakshaverPartstypeKode;
        const reporteesWithTiltakshaverPartstypeKode = reportees.filter((reportee) => {
            return reporteHasTiltakshaverPartstypeKode(reportee, tiltakshaverPartstypeKode);
        });
        if (!reporteesWithTiltakshaverPartstypeKode?.length) {
            const messageDescription = `No available Altinn reportees with TiltakshaverPartstypeKode: "${tiltakshaverPartstypeKode}"`;
            const logMessage = {
                level: "Warning",
                message: response?.reponseText?.length
                    ? `${response.reponseText} | ${messageDescription}`
                    : messageDescription,
                statuscode: response?.status,
                path: response?.url
            };
            clientLogger.postLogData([logMessage]);
        } else {
            const messageDescription = `${reporteesWithTiltakshaverPartstypeKode.length} available Altinn reportee${
                reporteesWithTiltakshaverPartstypeKode.length !== 1 ? "s" : ""
            } with TiltakshaverPartstypeKode: "${tiltakshaverPartstypeKode}"`;
            const logMessage = {
                level: "Debug",
                message: response?.reponseText?.length
                    ? `${response.reponseText} | ${messageDescription}`
                    : messageDescription,
                statuscode: response?.status,
                path: response?.url
            };
            clientLogger.postLogData([logMessage]);
        }
        dispatch({
            type: FETCH_AVAILABLE_REPORTEES,
            payload: reporteesWithTiltakshaverPartstypeKode
        });
        return reporteesWithTiltakshaverPartstypeKode;
    });
};

export const updateAvailableReportees = (availableReportees) => (dispatch) => {
    return dispatch({
        type: UPDATE_AVAILABLE_REPORTEES,
        payload: availableReportees
    });
};

export const updateSelectedReportee = (reportee) => {
    return {
        type: UPDATE_SELECTED_REPORTEE,
        payload: reportee
    };
};
