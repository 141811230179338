// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// DIBK Design
import { Button, NavigationBar } from "dibk-design";

// Stylesheets
import style from "components/partials/MainNavigationBar.module.scss";

// Assets
import signOutIcon from "assets/svg/right-from-bracket-solid.svg";

const MainNavigationBar = ({ userManager }) => {
    // Redux store
    const user = useSelector((state) => state.oidc?.user);

    const handleLogoutClick = (event) => {
        event.preventDefault();
        userManager.signoutRedirect({
            id_token_hint: user?.id_token
        });
        userManager.removeUser();
    };

    return (
        <NavigationBar logoLink="/" mainContentId="main-content" preventChildElementStacking>
            {user ? (
                <div className={style.rightContainer}>
                    <div className={style.logOutButtonContainer}>
                        <Button color="primary" onClick={handleLogoutClick} noMargin>
                            <img src={signOutIcon} alt="" />
                            <span aria-label="Logg ut">Logg ut</span>
                        </Button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </NavigationBar>
    );
};

export default MainNavigationBar;
