// Dependencies
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as oidcReducer } from 'redux-oidc';

// Reducers
import ArchiveReferenceReducer from 'reducers/ArchiveReferenceReducer';
import AttachmentsStatusReducer from 'reducers/AttachmentsStatusReducer';
import AttachmentsTransferCompleteReducer from 'reducers/AttachmentsTransferCompleteReducer';
import AvailableReporteesReducer from 'reducers/AvailableReporteesReducer';
import ClientLoggerReducer from 'reducers/ClientLoggerReducer';
import EmailReceiptIsSentReducer from 'reducers/EmailReceiptIsSentReducer';
import HasSamtykkeReducer from 'reducers/HasSamtykkeReducer';
import LastInteractionAtReducer from 'reducers/LastInteractionAtReducer';
import LoadingMessageReducer from 'reducers/LoadingMessageReducer'
import LogMessagesReducer from 'reducers/LogMessagesReducer'
import MessageIdReducer from 'reducers/MessageIdReducer';
import SamtykkeTiltakshaverReducer from 'reducers/SamtykkeTiltakshaverReducer';
import SelectedReporteeReducer from 'reducers/SelectedReporteeReducer';
import ShowAttachmentsStatusModalReducer from 'reducers/ShowAttachmentsStatusModalReducer';
import TiltakshaverSignaturXmlReducer from 'reducers/TiltakshaverSignaturXmlReducer';
import WizardStepsReducer from 'reducers/WizardStepsReducer';

const reducers = history => combineReducers({
  router: connectRouter(history),
  oidc: oidcReducer,
  lastInteractionAt: LastInteractionAtReducer,
  availableReportees: AvailableReporteesReducer,
  hasSamtykke: HasSamtykkeReducer,
  samtykkeTiltakshaver: SamtykkeTiltakshaverReducer,
  wizardSteps: WizardStepsReducer,
  selectedReportee: SelectedReporteeReducer,
  loadingMessage: LoadingMessageReducer,
  logMessages: LogMessagesReducer,
  clientLogger: ClientLoggerReducer,
  attachmentsStatus: AttachmentsStatusReducer,
  showAttachmentsStatusModal: ShowAttachmentsStatusModalReducer,
  attachmentsTransferComplete: AttachmentsTransferCompleteReducer,
  tiltakshaverSignaturXml: TiltakshaverSignaturXmlReducer,
  messageId: MessageIdReducer,
  archiveReference: ArchiveReferenceReducer,
  emailReceiptIsSent: EmailReceiptIsSentReducer
});

export default reducers;
