// Types
import {
    UPDATE_ATTACHMENTS_TRANSFER_COMPLETE
} from 'constants/types';

const initialState = false;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ATTACHMENTS_TRANSFER_COMPLETE:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;
