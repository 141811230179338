export const FETCH_AVAILABLE_REPORTEES = 'FETCH_AVAILABLE_REPORTEES';
export const UPDATE_AVAILABLE_REPORTEES = 'UPDATE_AVAILABLE_REPORTEES'; 
export const FETCH_SAMTYKKE_TILTAKSHAVER = 'FETCH_SAMTYKKE_TILTAKSHAVER';
export const UPDATE_WIZARD_STEPS = 'UPDATE_WIZARD_STEPS';
export const UPDATE_SELECTED_REPORTEE = 'UPDATE_SELECTED_REPORTEE';
export const UPDATE_LOADING_MESSAGE = 'UPDATE_LOADING_MESSAGE';
export const UPDATE_HAS_SAMTYKKE = 'UPDATE_HAS_SAMTYKKE';
export const UPDATE_MESSAGE_ID = 'UPDATE_MESSAGE_ID';
export const FETCH_TILTAKSHAVER_SIGNATUR_XML = 'FETCH_TILTAKSHAVER_SIGNATUR_XML';
export const UPDATE_SHOW_ATTACHMENTS_STATUS_MODAL = 'UPDATE_SHOW_ATTACHMENTS_STATUS_MODAL';
export const UPDATE_SINGLE_ATTACHMENT_STATUS_ATTACHMENT = 'UPDATE_SINGLE_ATTACHMENT_STATUS_ATTACHMENT';
export const UPDATE_SINGLE_ATTACHMENT_STATUS_DOWNLOAD_PROGRESS = 'UPDATE_SINGLE_ATTACHMENT_STATUS_DOWNLOAD_PROGRESS';
export const UPDATE_SINGLE_ATTACHMENT_STATUS_UPLOAD_PROGRESS = 'UPDATE_SINGLE_ATTACHMENT_STATUS_UPLOAD_PROGRESS';
export const UPDATE_SINGLE_ATTACHMENT_STATUS_ERROR_MESSAGES = 'UPDATE_SINGLE_ATTACHMENT_STATUS_ERROR_MESSAGE';
export const UPDATE_ATTACHMENTS_TRANSFER_COMPLETE = 'UPDATE_ATTACHMENTS_TRANSFER_COMPLETE';
export const UPDATE_ARCHIVE_REFERENCE = 'UPDATE_ARCHIVE_REFERENCE';
export const UPDATE_EMAIL_RECEIPT_IS_SENT = 'UPDATE_EMAIL_RECEIPT_IS_SENT';
export const UPDATE_LAST_INTERACTION_AT = 'UPDATE_LAST_INTERACTION_AT';
export const UPDATE_LOG_MESSAGES = 'UPDATE_LOG_MESSAGES';
export const CLEAR_LOG_MESSAGES = 'CLEAR_LOG_MESSAGES';
export const UPDATE_CLIENT_LOGGER = 'UPDATE_CLIENT_LOGGER';