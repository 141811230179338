// Types
import {
    UPDATE_SINGLE_ATTACHMENT_STATUS_ATTACHMENT,
    UPDATE_SINGLE_ATTACHMENT_STATUS_DOWNLOAD_PROGRESS,
    UPDATE_SINGLE_ATTACHMENT_STATUS_UPLOAD_PROGRESS,
    UPDATE_SINGLE_ATTACHMENT_STATUS_ERROR_MESSAGES
} from 'constants/types';

const initialState = [];

const reducer = (state = initialState, action) => {
    let newVedleggStatusList = [...state];
    switch (action.type) {
        case UPDATE_SINGLE_ATTACHMENT_STATUS_ATTACHMENT:
            newVedleggStatusList[action.index] = {
                ...newVedleggStatusList[action.index],
                attachment: action.payload
            };
            return newVedleggStatusList;
        case UPDATE_SINGLE_ATTACHMENT_STATUS_DOWNLOAD_PROGRESS:
            newVedleggStatusList[action.index] = {
                ...newVedleggStatusList[action.index],
                downloadProgress: action.payload
            };
            return newVedleggStatusList;
        case UPDATE_SINGLE_ATTACHMENT_STATUS_UPLOAD_PROGRESS:
            newVedleggStatusList[action.index] = {
                ...newVedleggStatusList[action.index],
                uploadProgress: action.payload
            };
            return newVedleggStatusList;
        case UPDATE_SINGLE_ATTACHMENT_STATUS_ERROR_MESSAGES:
            newVedleggStatusList[action.index] = {
                ...newVedleggStatusList[action.index],
                errorMessages: action.payload
            };
            return newVedleggStatusList;
        default:
            return state;
    }
}

export default reducer;
