// Dependencies
import React from 'react';

// Partials
import ReporteesListItem from 'components/partials/ReporteesList/ReporteesListItem';

// Stylesheets
import style from 'components/partials/ReporteesList.module.scss';

const ReporteesList = ({ reportees, hasErrors }) => {

  const renderReporteesListItems = () => {
    return reportees?.length
      ? reportees.map(reportee => {
        return <ReporteesListItem key={reportee.ReporteeId} reportee={reportee} hasErrors={hasErrors} />
      }) : ''
  }

  return (
    <ul className={style.reporteesList}>
      {renderReporteesListItems()}
    </ul>
  )
}

export default ReporteesList;
