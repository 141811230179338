import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignoutCallbackComponent } from "redux-oidc";

const OidcSignoutCallback = ({ userManager }) => {
    const navigate = useNavigate();

    // State
    const [isLoggedOut, setIsLoggedOut] = useState();

    const successCallback = () => {
        setIsLoggedOut(true);
    };
 
    useEffect(() => {
        if (isLoggedOut){
            navigate("/"); // TODO: add signed out page
        }
    }, [isLoggedOut, navigate])

    return (
        <SignoutCallbackComponent
            userManager={userManager}
            successCallback={successCallback}
            errorCallback={() => {
                navigate("/"); // TODO: add errorpage for signout
            }}
        >
            <div>Logger ut...</div>
        </SignoutCallbackComponent>
    );
};

export default OidcSignoutCallback;
