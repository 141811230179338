// Actions
import {
    updateSingleAttachmentStatusAttachment,
    updateSingleAttachmentStatusDownloadProgress,
    updateSingleAttachmentStatusUploadProgress,
    addSingleAttachmentStatusErrorMessage
} from "actions/AttachmentsStatusActions";

import { updateMessage } from "actions/MessageActions";

// Helpers
import { getAttachmentDownloadUrl, getAttachmentUploadUrl } from "helpers/urlHelpers";
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers";
import { getXhrResponseText } from "helpers/apiHelpers";

export const streamAttachments = (samtykkeTiltakshaver, messageId) => (dispatch, getState) => {
    const guid = samtykkeTiltakshaver.Id;
    if (samtykkeTiltakshaver?.Vedlegg?.length) {
        samtykkeTiltakshaver.Vedlegg.filter((attachment) => {
            return attachment?.Navn?.length;
        }).forEach((attachment, attachmentIndex) => {
            dispatch(updateSingleAttachmentStatusAttachment(attachment, attachmentIndex));
            dispatch(getAttachementsStream(attachment, attachmentIndex, guid, messageId));
        });
    } else {
        dispatch(updateMessage(messageId));
    }
};

const getAttachementsStream = (attachment, attachmentIndex, guid, messageId) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;

    const request = new XMLHttpRequest();
    const requestUrl = getAttachmentDownloadUrl(attachment, guid);

    request.addEventListener("readystatechange", (event) => {
        if (request.readyState === 2 && request.status === 200) {
        } else if (request.readyState === 3) {
        } else if (request.readyState === 4) {
            const _OBJECT_URL = URL.createObjectURL(request.response);
            if (request.status === 200) {
                const logMessage = {
                    level: "Debug",
                    path: requestUrl,
                    message: `Streamed${attachment?.Navn?.length ? " '" + attachment.Navn + "'" : ""} ${attachment?.Vedleggstype ? "with attachment type '" + attachment.Vedleggstype + "'" : "without attachment type"}`
                };
                clientLogger.postLogData([logMessage]);
                dispatch(postAttachementsStream(attachment, request.response, attachmentIndex, messageId));
            } else {
                dispatch(
                    addSingleAttachmentStatusErrorMessage(
                        `Feil ved henting av vedlegg: ${attachment.Navn}`,
                        attachmentIndex
                    )
                );
                const logMessage = {
                    level: "Error",
                    message: getXhrResponseText(event.target) || `Failed to get attachment: ${attachment.Navn}`,
                    path: requestUrl,
                    statuscode: event?.target?.status
                };
                clientLogger.postLogData([logMessage]);
            }
            setTimeout(function () {
                window.URL.revokeObjectURL(_OBJECT_URL);
            }, 60 * 1000);
        }
    });

    request.addEventListener("progress", (event) => {
        const downloadProgress = Math.round((event.loaded / event.total) * 100);
        dispatch(updateSingleAttachmentStatusDownloadProgress(downloadProgress, attachmentIndex));
    });

    request.addEventListener(
        "error",
        (event) => {
            dispatch(
                addSingleAttachmentStatusErrorMessage(
                    `Feil ved henting av vedlegg: ${attachment.Navn}`,
                    attachmentIndex
                )
            );
            const logMessage = {
                level: "Error",
                message: getXhrResponseText(event.target) || `Failed to get attachment: ${attachment.Navn}`,
                path: requestUrl
            };
            clientLogger.postLogData([logMessage]);
        },
        false
    );

    request.responseType = "blob";
    request.open("get", requestUrl);
    const bearerToken = `Bearer ${accessToken}`;
    request.setRequestHeader("Authorization", bearerToken);
    request.send();
};

const postAttachementsStream = (attachment, data, attachmentIndex, messageId) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;
    let formdata = new FormData();
    formdata.append("file", data, attachment.Navn);
    const request = new XMLHttpRequest();
    const requestUrl = getAttachmentUploadUrl(attachment, messageId);

    request.upload.addEventListener(
        "progress",
        (event) => {
            const uploadProgress = Math.round((event.loaded / event.total) * 100);
            dispatch(updateSingleAttachmentStatusUploadProgress(uploadProgress, attachmentIndex));
        },
        false
    );

    // Success
    request.addEventListener(
        "load",
        (event) => {
            const status = event?.target?.status;
            if (status === 200 || status === 201 || status === 0) {
                const logMessage = {
                    level: "Debug",
                    path: requestUrl,
                    message: `Uploaded${attachment?.Navn?.length ? " '" + attachment.Navn + "'" : ""} ${attachment?.Vedleggstype ? "with attachment type '" + attachment.Vedleggstype + "'" : "without attachment type"}`
                };
                clientLogger.postLogData([logMessage]);
                dispatch(updateMessage(messageId));
            } else {
                dispatch(addSingleAttachmentStatusErrorMessage(event.target.statusText, attachmentIndex));
                const logMessage = {
                    level: "Error",
                    message: getXhrResponseText(event.target) || `Failed to upload attachment: ${attachment.Navn}`,
                    statuscode: event?.target?.status,
                    path: event?.target?.responseURL
                };
                clientLogger.postLogData([logMessage]);
            }
        },
        false
    );

    request.addEventListener(
        "error",
        (event) => {
            dispatch(
                addSingleAttachmentStatusErrorMessage(
                    `Feil ved opplasting av vedlegg: ${attachment.Navn}`,
                    attachmentIndex
                )
            );
            const logMessage = {
                level: "Error",
                message: getXhrResponseText(event.target) || `Failed to upload attachment: ${attachment.Navn}`,
                path: requestUrl
            };
            clientLogger.postLogData([logMessage]);
        },
        false
    );

    request.open("POST", requestUrl);

    const apiKey = getEnvironmentVariable("altinnApiKey");
    const bearerToken = `Bearer ${accessToken}`;
    request.setRequestHeader("ApiKey", apiKey);
    request.setRequestHeader("Authorization", bearerToken);
    request.setRequestHeader("Content-Type", "application/octet-stream");

    request.send(formdata);
};

export const getAttachementFile = (attachment, guid) => (dispatch, getState) => {
    const accessToken = getState()?.oidc?.user?.access_token;
    const clientLogger = getState()?.clientLogger;

    const apiUrl = getAttachmentDownloadUrl(attachment, guid);
    const apiKey = getEnvironmentVariable("altinnApiKey");

    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        method: "GET",
        headers: {
            Authorization: bearerToken,
            ApiKey: apiKey
        }
    };
    return fetch(apiUrl, fetchOptions)
        .then((response) => {
            if (!response.ok) {
                throw response.status;
            } else {
                const logMessage = {
                    level: "Debug",
                    path: apiUrl,
                    message: `User downloaded${attachment?.Navn?.length ? " '" + attachment.Navn + "'" : ""} ${attachment?.Vedleggstype ? "with attachment type '" + attachment.Vedleggstype + "'" : "without attachment type"}`
                };
                clientLogger.postLogData([logMessage]);
                return response.blob();
            }
        })
        .catch((error) => {
            const logMessage = {
                level: "Error",
                path: apiUrl
            };
            clientLogger.getLogMessageFromError(error, logMessage).then((logMessage) => {
                clientLogger.postLogData([logMessage]);
            });
            return { error };
        });
};
