// Dependencies
import { useEffect } from "react";

// DIBK Design
import { Container, Header, List, ListItem, Paper } from "dibk-design";

// Partials
import MainHeading from "components/partials/MainHeading";

// Helpers
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/common.module.scss";

const About = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container>
            <Paper>
                <div className={commonStyle.headerSection}>
                    <MainHeading
                        pageHeading="Om tiltakshavers samtykke til byggeplaner"
                        pageTitle="Om tiltakshavers samtykke til byggeplaner"
                    />
                </div>
                <div>
                    <Header content="Om veiviseren" size={2} />
                    <p>
                        Veiviseren for tiltakshavers samtykke til byggeplaner er utviklet av Direktoratet for
                        byggkvalitet.
                    </p>
                    <Header content="Slik fungerer veiviseren:" size={3} />
                    <List ordered>
                        <ListItem>
                            Ansvarlig søker gjør søknaden med alle vedlegg klar for sending til kommunen
                        </ListItem>
                        <ListItem>
                            Når søknaden er klar, sender ansvarlig søker deg som tiltakshaver en unik lenke til
                            Tiltakshavers samtykke
                        </ListItem>
                        <ListItem>
                            Bruk den unike lenken for å logge deg inn på tjenesten og velg hvem du vil signere på vegne
                            av. Ofte er det deg selv, men du må velge foretaket dersom det er et firma som står som
                            tiltakshaver
                        </ListItem>
                        <ListItem>
                            Se gjennom søknaden med vedlegg. Hvis du samtykker, må du huke av for det og sende ditt
                            samtykke
                        </ListItem>
                        <ListItem>
                            Samtykket blir sendt tilbake til søknadssystemet ansvarlig søker har valgt, og legges
                            automatisk ved søknaden som ansvarlig søker sender til kommunen
                        </ListItem>
                    </List>
                    <p>Samtykket blir også lagret i arkivet ditt i Altinn sammen med søknaden og vedleggene.</p>
                    <Header content="Har du spørsmål?" size={3} />
                    Se vanlig stilte spørsmål og svar på{" "}
                    <a href="https://dibk.no/tiltakshavers-samtykke" target="_blank" rel="noopener noreferrer">
                        dibk.no/tiltakshavers-samtykke
                    </a>
                    .
                </div>
            </Paper>
        </Container>
    );
};

export default About;
