// Types
import { UPDATE_LAST_INTERACTION_AT } from "constants/types";

export const updateLastInteractionAt = (lastInteractionAt) => (dispatch) => {
    dispatch({ type: UPDATE_LAST_INTERACTION_AT, payload: lastInteractionAt });
}

export const renewLastInteractionAt = () => (dispatch) => {
    dispatch(updateLastInteractionAt(Date.now()));
}


